import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Space } from "antd";

import { createCompany } from "../../../../../api/companyApi";

import Popup from "../../../../../components/ui-components/Popup/Popup";
import FileUpload from "../../../../../components/FileUpload";
import CardActions from "../../../../../Admin/pages/components/CardActions";
import CompanyIDInput from "../../../../../components/CompanyIDInput";
import CompanyNameAutocomplete from "../../../../../components/CompanyNameAutocomplete";

const initAuditData = {
  logo: "",
  logoURL: "",
  companyName: "",
  companyId: "",
};

const CreateCompany = ({ isOpen, onClose, onSuccess }) => {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const [error, setError] = useState(false);
  const [activeData, setActiveData] = useState(false);
  const [auditData, setAuditData] = useState(initAuditData);

  const logo = auditData.logoURL;
  const companyNameRef = useRef(null);

  useEffect(() => {
    if (isOpen && companyNameRef.current) {
      companyNameRef.current.focus();
    }
  }, [isOpen]);

  const onSubmit = async () => {
    const { companyId, companyName } = auditData;
    if (!(companyId && companyName)) {
      setError(true);
      return;
    }
    try {
      const res = await createCompany({
        logo: auditData.logo,
        UID: companyId,
        name: companyName,
      });
      if (res.company) {
        onClose(false);
        setAuditData(initAuditData);
        onSuccess?.();
      } else {
        setError(res.response?.data.message);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    setActiveData(auditData.companyName.length > 0 && auditData.companyId.length > 0);
  }, [auditData]);

  const inputData = (key, value) => {
    setAuditData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setError(false);
  };

  const onCompanySelect = (company) => {
    setAuditData((prevState) => ({
      ...prevState,
      companyName: company.name,
      companyId: company.uid,
    }));
  };

  return (
    <Popup isOpen={isOpen} title={tGlobal("create_company")} onClose={onClose}>
      <Space.Compact direction='vertical' block className='popup-body'>
        <Row gutter={24}>
          <Col span={8}>
            <span>{tGlobal("company_logo")}</span>
          </Col>
          <Col span={16}>
            <FileUpload
              title={tGlobal("select_picture")}
              file={logo}
              subtitle={"JPG, PNG"}
              accept={{
                "image/jpeg": [],
                "image/png": [],
              }}
              onChange={(file) => {
                inputData("logo", file);
                inputData("logoURL", file ? URL.createObjectURL(file) : "");
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <span>{tGlobal("company_name")}</span>
          </Col>
          <Col span={16}>
            <CompanyNameAutocomplete
              ref={companyNameRef}
              error={error === "Company already exists"}
              onChange={inputData}
              value={auditData.companyName}
              onSelect={onCompanySelect}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <span className='company_field'>{tGlobal("company_id")}</span>
          </Col>
          <Col span={16}>
            <CompanyIDInput
              error={error && !auditData.companyId}
              value={auditData.companyId}
              onChange={inputData}
            />
          </Col>
        </Row>
      </Space.Compact>
      {/*{error && <span className='wrapper_inputAddCompany_errors'>{error}</span>}*/}
      <CardActions
        onCancel={() => onClose(false)}
        onSubmit={onSubmit}
        submitText={tGlobal("create")}
        submitDisabled={!activeData}
      />
    </Popup>
  );
};
export default CreateCompany;
