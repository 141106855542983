import { ReactElement, useEffect, useState } from "react";
import { ZefixCompany } from "../../types/audit";

import { Input } from "../ui-components";
import { Dropdown, MenuProps, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { fetchZefixCompanies } from "../../api/companyApi";

type CompanyNameAutocompleteProps = {
  error?: boolean;
  value?: string;
  onChange: (name: string, value: string) => void;
  onSelect: (company: ZefixCompany) => void;
};

export default function CompanyNameAutocomplete({
  error,
  value,
  onChange,
  onSelect,
}: CompanyNameAutocompleteProps) {
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState<
    Array<ZefixCompany & { key: string; label: ReactElement }>
  >([]);

  const handleChange = (name: string, value: string) => {
    onChange(name, value);
  };

  useEffect(() => {
    if (!value || value.length < 1) return;

    const controller = new AbortController();
    const { signal } = controller;

    const fetchCompanies = async () => {
      try {
        const companies = await fetchZefixCompanies(value, { signal });
        if (Array.isArray(companies)) {
          setOptions(
            companies.map((company) => ({
              ...company,
              label: (
                <Space align='center'>
                  {company.name}{" "}
                  <Tag>{company.uid.replace(/^CHE(\d{3})(\d{3})(\d{3})$/, "CHE-$1.$2.$3")}</Tag>
                </Space>
              ),
              key: company.uid,
            }))
          );
        } else {
          setOptions([]);
        }
      } catch (error: any) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch companies:", error);
        }
      }
    };

    fetchCompanies();

    return () => controller.abort();
  }, [value]);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const company = options.find((s) => s.key === e.key);
    if (company) {
      onSelect(company);
    }
  };

  return (
    <Dropdown
      open={isFocused && !!value && value.length > 0}
      menu={{ items: options, onClick: handleMenuClick }}
    >
      <Input
        autoFocus
        error={error}
        errorMessage={tGlobal("error_input_company_name")}
        onHandleChange={handleChange}
        value={value}
        name='companyName'
        placeholder='e.g. aivo'
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 300)}
      />
    </Dropdown>
  );
}
