import { Row, Col, Tag } from "antd";
import clsx from "clsx";
import React, { forwardRef } from "react";

import { Button } from "../../../../../components/ui-components";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import UserNameWithStatus from "../../../../../components/UserNameWithStatus";
import styles from "./SignerInfo.module.scss";

type SignerInfoProps = {
  small?: boolean;
  signer: any; // todo
  footnoteText?: string;
  isSelected: boolean;
  onClose?: (id: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const SignerInfo = forwardRef<HTMLDivElement, SignerInfoProps>(
  ({ signer, small, footnoteText, isSelected, onClose, ...props }, ref) => {
    const fullName = signer.user.firstName
      ? `${signer.user.firstName} ${signer.user.lastName}`
      : signer.user.email;

    return (
      <div
        ref={ref}
        className={clsx(
          styles.signerInfo,
          isSelected && styles.signerInfoActive,
          small && styles.small
        )}
        {...props}
      >
        {isSelected && onClose && (
          <Button
            color='gray'
            className={styles.close}
            icon={<CloseIcon />}
            onClick={() => onClose(signer.id)}
          />
        )}
        {signer.user.homeTeam?.name && (
          <div className={styles.companyName}>{signer.user.homeTeam.name}</div>
        )}
        {signer.homeTeam?.name && <div className={styles.companyName}>{signer.homeTeam.name}</div>}
        <div className={styles.userName}>
          <UserNameWithStatus
            name={fullName}
            role={signer.user.role}
            signatoryStatus={signer.user.signatoryStatus}
            signatory={false}
          />
        </div>
        <Row gutter={[8, 8]} justify='space-between' align='middle'>
          <Col>
            {signer.user.homeTeam?.signatory && (
              <Tag className={styles.companySignatory}>{signer.user.homeTeam.signatory}</Tag>
            )}
            {signer.homeTeam?.signatory && (
              <Tag className={styles.companySignatory}>{signer.homeTeam.signatory}</Tag>
            )}
          </Col>
          <Col>
            <div className={styles.secondaryText}>{footnoteText}</div>
          </Col>
        </Row>
      </div>
    );
  }
);

export default SignerInfo;
