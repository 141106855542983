import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CheckData from "./CheckData";
import Attachments from "../Admin/pages/components/Attachments/Attachments";

import "./styles/newDocument.css";
import { useDocuments } from "../hooks/services/useDocuments";
import { DocumentType } from "../enums";
import FormPicker from "../Admin/pages/components/FormPicker/FormPicker";
import DropzoneUpload from "../pages/dashboard/Documents/components/DropzoneUpload/DropzoneUpload";
import { categoryOptions } from "../pages/dashboard/Documents/Documents.constant";

const NewDocument = ({ close, activeTab }) => {
  const { id: idAudit } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });

  const [files, setFiles] = useState([]);
  const [name, setName] = useState(false);
  const [activeCategory, setActiveCategory] = useState(
    activeTab === "all" || activeTab === "unlinked" ? DocumentType.evidence : activeTab
  );
  const [activeData, setActiveData] = useState(false);
  const [error, setError] = useState({
    name: false,
    file: false,
  });
  const { createDocument } = useDocuments();

  const onSubmit = () => {
    if (activeData) {
      createDocument({
        body: {
          name: name,
          document: files[0],
          type: activeCategory || DocumentType.evidence,
        },
        auditId: idAudit,
      });
      close(false);
    } else {
      setError({
        name: !name,
        file: !files,
      });
    }
  };

  const onFileDrop = (files) => {
    setFiles([files[0]]);
    setError((prevState) => ({
      ...prevState,
      file: false,
    }));
  };

  useEffect(() => {
    setActiveData(name.length > 0 && files.length > 0);
    // eslint-disable-next-line
  }, [name, files]);

  return (
    <div className='sub_menu_document'>
      <DropzoneUpload className='settings_right-audit' onDrop={onFileDrop} multiple>
        <span className='popup-title'>{t("upload_document")}</span>
        <hr className='documents_line-create' />
        <div className='block-input'>
          <div className='block-row'>
            <span className='title-input'>{t("upload_file")}</span>
            <div className='category-line description attachments'>
              <Attachments
                newDocument
                multiple={false}
                attachments={files}
                onAddFile={onFileDrop}
                onRemoveFile={(deletedId) => {
                  setFiles((prev) => prev.filter((f) => f.id !== deletedId));
                }}
                noLabel
              />
            </div>
          </div>
          <div className='block-row'>
            <span className='title-input'>{t("filename")}</span>
            <input
              className={`${error.name ? "errorActive" : "inputNewDocuments"} `}
              placeholder='e.g. Angebot'
              onChange={({ target }) => {
                setName(target.value);
                setError((prevState) => ({
                  ...prevState,
                  name: false,
                }));
              }}
            />
          </div>

          <div className='block-row'>
            <span className='title-input'>{t("category")}</span>
            <FormPicker
              options={categoryOptions.map((i) => ({ name: t(i), value: i }))}
              selected={activeCategory}
              onSelect={(_, value) => setActiveCategory(value)}
            />
          </div>
        </div>
        <hr className='documents_line-create' />
        <div className='addAuditBtn'>
          <CheckData
            discard={() => {
              close(false);
            }}
            onClick={onSubmit}
            text={t("upload")}
            activeBtn={activeData}
          />
        </div>
      </DropzoneUpload>
    </div>
  );
};

export default NewDocument;
