import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { oneAudit } from "../../../api/auditApi";
import { getDocumentBacklinks } from "../../../api/documentApi";
import { getMeetings } from "../../../api/meetingsAPI";
import { allRequests } from "../../../api/requestsPage";
import { getStatusAudit } from "../../../api/statusApi";
import { useFetch } from "../../../hooks";

import { Loading } from "../../../components/ui-components";
import StatusSection from "./components/StatusSection/StatusSection";
import RequestSection from "./components/RequestSection";
import DocumentSection from "./components/DocumentSection";
import MeetingSection from "./components/MeetingSection";

import str_right from "../../../assets/admin/str_right.svg";

import "../../styles/overview.css";
import { useQuery } from "@tanstack/react-query";

const Overview = () => {
  const { id: idAudit } = useParams();
  const history = useHistory();
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const userRole = useSelector((state) => state.user.user_role);
  const { isLoading, data } = useFetch(oneAudit, idAudit);

  // const [activeTab, setActiveTab] = useState("tasks");
  const [allMeetings, setAllMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatusGroup, setSelectedStatusGroup] = useState(null);
  const [statusAudit, setStatusAudit] = useState(null);
  const [statusRequest, setStatusRequest] = useState(false);
  const { data: documents, loading: isDocumentLoading } = useQuery({
    staleTime: Infinity,
    queryKey: ["documents"],
    queryFn: () => getDocumentBacklinks(idAudit),
    placeholderData: [],
  });

  const selector = userRole?.role;
  const hasAdminPermissions = userRole?.hasAdminPermissions;

  const [audit, statusGroups] = useMemo(() => {
    if (!statusAudit?.audit) return [null, null];
    setSelectedStatusGroup(
      statusAudit.audit.statusGroups
        ?.sort((a, b) => b.order - a.order)
        ?.find((i) => i.steps.find((j) => j.status === "in_progress" || j.status === "done"))?.id
    );
    return [statusAudit.audit, statusAudit.audit.statusGroups];
  }, [statusAudit]);

  const [auditor, auditorFirm] = useMemo(() => {
    if (!data?.audit) return [];
    return [
      data.audit?.auditors?.find((i) => i.role === "lead_auditor")?.member?.user,
      data.audit?.auditorFirm,
    ];
  }, [data]);

  const fetchData = async () => {
    if (hasAdminPermissions || selector?.statusAccess) {
      const res = await getStatusAudit(idAudit);
      setStatusAudit(res);
    }
    if (hasAdminPermissions || selector?.requestsAccess) {
      const res = await allRequests(idAudit, { assigned: true });
      setStatusRequest(res.status);
    }
    if (hasAdminPermissions || selector?.meetingAccess) {
      const res = await getMeetings(idAudit);
      setAllMeetings(res?.meetings["Upcoming Meetings"] || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hasAdminPermissions !== undefined) {
      fetchData();
    }
  }, [hasAdminPermissions]);

  return (
    <div className='wrapper_overview'>
      {loading || isLoading || isDocumentLoading || !userRole ? (
        <div className='loading_wrapper'>
          <Loading position='absoluteCenter' />
        </div>
      ) : (
        <>
          <div className={"overview_content"}>
            <span className={"title"}>{tGlobal("dashboard")}</span>
            <div className={"audit_status_wrapper content_box"}>
              <div
                className={"header"}
                onClick={() => {
                  history.push(`/dashboard/${idAudit}/status/`);
                }}
              >
                <span className={"header__title"}>{tGlobal("status")}</span>
                {(hasAdminPermissions || (!!selector && selector.statusAccess)) && (
                  <Link to={`/dashboard/${idAudit}/status`}>
                    <img src={str_right} alt='Right' />
                  </Link>
                )}
              </div>
              {(hasAdminPermissions || (!!selector && selector.statusAccess)) && (
                <StatusSection
                  audit={audit}
                  selectedStatusGroup={selectedStatusGroup}
                  statusGroups={statusGroups}
                  auditor={auditor}
                  auditorFirm={auditorFirm}
                />
              )}
            </div>
            {(hasAdminPermissions || (!!selector && selector.requestsAccess)) && (
              <RequestSection idAudit={idAudit} statusRequest={statusRequest} />
            )}
            {(hasAdminPermissions || (!!selector && selector.documentAccess)) && (
              <DocumentSection allDocument={documents} idAudit={idAudit} />
            )}

            {(hasAdminPermissions || (!!selector && selector.meetingAccess)) && (
              <MeetingSection allMeetings={allMeetings} idAudit={idAudit} />
            )}
          </div>
          <div className={"wrapper_tabs"}>
            {/*<div className={"tabs"}>*/}
            {/*  <span*/}
            {/*    className={`${activeTab === "tasks" ? "active" : ""} tab`}*/}
            {/*    onClick={() => setActiveTab("tasks")}*/}
            {/*  >*/}
            {/*    Tasks*/}
            {/*  </span>*/}
            {/*  <span*/}
            {/*    className={`${activeTab === "history" ? "active" : ""} tab`}*/}
            {/*    onClick={() => setActiveTab("history")}*/}
            {/*  >*/}
            {/*    History*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<div className={"content"}>*/}
            {/*  {activeTab === "tasks" ? (*/}
            {/*    <div className={"content__info"}>*/}
            {/*      <div className={"every_block every_block_red"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          <b>You</b> need to upload <b>[name of the file]</b>*/}
            {/*          <span style={{ color: "rgba(255, 84, 84, 1)" }}>*/}
            {/*            {" "}*/}
            {/*            by tomorrow, 1.00pm*/}
            {/*          </span>*/}
            {/*        </span>*/}
            {/*        <Button className={"btn_bluelight"} text={"Upload"} />*/}
            {/*      </div>*/}
            {/*      <div className={"every_block"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          <b>You</b> have a scheduled meeting <b>[name of the file]</b>*/}
            {/*        </span>*/}
            {/*        <p className={"date"}>10.02.2021, 2.30pm</p>*/}
            {/*        <Button className={"btn_bluelight"} text={"Upload"} />*/}
            {/*      </div>*/}
            {/*      <div className={"every_block"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          <b>You</b> have a scheduled meeting <b>[name of the file]</b>*/}
            {/*        </span>*/}
            {/*        <p className={"date"}>10.02.2021, 2.30pm</p>*/}
            {/*        <Button*/}
            {/*          className={"btn_bluelight btn_border_delete"}*/}
            {/*          text={"See AccountSettingsProfile"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className={"every_block"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          <b>You</b> need to upload <b>[name of the file]</b>*/}
            {/*        </span>*/}
            {/*        <p className={"date"}>10.02.2021, 2.30pm</p>*/}
            {/*        <Button*/}
            {/*          className={"btn_bluelight btn_border_delete"}*/}
            {/*          text={"See AccountSettingsProfile"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className={"every_block"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          <b>You</b> have a scheduled meeting <b>[name of the file]</b>*/}
            {/*        </span>*/}
            {/*        <p className={"date"}>10.02.2021, 2.30pm</p>*/}
            {/*        <Button*/}
            {/*          className={"btn_bluelight btn_border_delete"}*/}
            {/*          text={"See AccountSettingsProfile"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  ) : (*/}
            {/*    <div className={"content__info"}>*/}
            {/*      <div className={"every_block every_block_red"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          Name Surname accepted Revisionsstellenbericht*/}
            {/*        </span>*/}
            {/*        <p className={"date"}>10.02.2021, 2.30pm</p>*/}
            {/*        <Button*/}
            {/*          className={"btn_bluelight btn_border_delete"}*/}
            {/*          text={"See document"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className={"every_block every_block_grey"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          You uploaded Revisionsstellenbericht*/}
            {/*        </span>*/}
            {/*        <p className={"date"}>07.02.2021, 5.20pm</p>*/}
            {/*        <Button*/}
            {/*          className={"btn_bluelight btn_border_delete"}*/}
            {/*          text={"See document"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className={"every_block every_block_grey"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>*/}
            {/*          Name Surname commented on Revisionsstellenbericht*/}
            {/*        </span>*/}
            {/*        <p className={"date"}>07.02.2021, 5.20pm</p>*/}
            {/*        <Button*/}
            {/*          className={"btn_bluelight btn_border_delete"}*/}
            {/*          text={"See document"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*      <div className={"every_block every_block_grey"}>*/}
            {/*        <div className={"navigation_point"} />*/}
            {/*        <span className={"preview"}>Kick-off meeting</span>*/}
            {/*        <p className={"date"}>09.01.2021, 3.00pm</p>*/}
            {/*        <Button*/}
            {/*          className={"btn_bluelight btn_border_delete"}*/}
            {/*          text={"See AccountSettingsProfile"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
