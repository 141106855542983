import React from "react";

export const DoneIcon = () => (
  <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_10645_19947)'>
      <path
        d='M0 30C0 37.9565 3.1607 45.5871 8.7868 51.2132C14.4129 56.8393 22.0435 60 30 60C37.9565 60 45.5871 56.8393 51.2132 51.2132C56.8393 45.5871 60 37.9565 60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.1607 37.9565 0 30 0C22.0435 0 14.4129 3.1607 8.7868 8.7868C3.1607 14.4129 0 22.0435 0 30Z'
        fill='#49BACA'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.8541 19.9967C47.1429 20.3051 47.368 20.6675 47.5165 21.063C47.6651 21.4585 47.7343 21.8795 47.72 22.3018C47.7057 22.724 47.6084 23.1394 47.4335 23.524C47.2585 23.9086 47.0095 24.2549 46.7006 24.5432L26.6564 43.286C26.0461 43.8564 25.2377 44.1668 24.4024 44.1514C23.5672 44.1361 22.7707 43.7961 22.1818 43.2036L14.6231 35.61C14.3253 35.3109 14.0894 34.956 13.9288 34.5656C13.7681 34.1753 13.6859 33.7571 13.6869 33.335C13.6879 32.9129 13.772 32.4951 13.9344 32.1055C14.0968 31.7159 14.3344 31.3621 14.6336 31.0643C14.9327 30.7666 15.2876 30.5306 15.678 30.37C16.0683 30.2093 16.4865 30.1272 16.9086 30.1281C17.3307 30.1291 17.7485 30.2132 18.1381 30.3756C18.5277 30.5381 18.8815 30.7756 19.1793 31.0748L24.5423 36.4614L42.3027 19.8468C42.6109 19.5577 42.9731 19.3322 43.3685 19.1832C43.7639 19.0342 44.1849 18.9646 44.6072 18.9785C45.0296 18.9924 45.445 19.0894 45.8299 19.264C46.2147 19.4386 46.5655 19.688 46.8541 19.9967Z'
        fill='#49BACA'
      />
    </g>
    <defs>
      <clipPath id='clip0_10645_19947'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const colorMap = {
  dark: "#003E47CC",
  gray: "#003e47",
  blue: "0F8594FF",
  green: "569720FF",
  orange: "F17F16FF",
};

export const CloseRoundedIcon = (props) => {
  const color = props.color;
  const colorMapped = color ? { color: colorMap[color] || color } : {};
  return (
    <svg
      width='16'
      height='16'
      color='#0F8594'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      {...colorMapped}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8285 10.8287C11.219 10.4381 11.219 9.80496 10.8285 9.41444L9.41442 8.00036L10.8282 6.58662C11.2187 6.19609 11.2187 5.56293 10.8282 5.1724C10.4376 4.78188 9.80448 4.78188 9.41395 5.1724L8.00021 6.58615L6.58586 5.1718C6.19534 4.78128 5.56217 4.78128 5.17165 5.1718C4.78112 5.56232 4.78112 6.19549 5.17165 6.58601L6.586 8.00036L5.17131 9.41504C4.78079 9.80557 4.78079 10.4387 5.17131 10.8293C5.56184 11.2198 6.195 11.2198 6.58552 10.8293L8.00021 9.41457L9.41429 10.8287C9.80481 11.2192 10.438 11.2192 10.8285 10.8287Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const NotificationIcon = () => (
  <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M25.3317 20.0612C26.2497 20.0612 27 20.7915 27 21.6939C27 22.5931 26.2513 23.3265 25.3285 23.3265H8.67153C7.74704 23.3265 7 22.5963 7 21.6939C7 20.7947 7.74708 20.0612 8.66828 20.0612C9.12565 20.0612 9.49998 19.6961 9.49998 19.2449V14.3469C9.49998 10.2876 12.8577 7 16.9999 7C21.1421 7 24.4999 10.2892 24.4999 14.3469V19.2449C24.4999 19.6977 24.8726 20.0612 25.3316 20.0612H25.3317ZM14.0835 24.1429H19.9168C19.9168 25.7213 18.6114 27 17.0001 27C15.3888 27 14.0835 25.7213 14.0835 24.1429Z'
      fill='#003E47'
      fillOpacity='0.6'
    />
  </svg>
);

export const TrashIcon = (props) => (
  <svg
    width='34'
    height='35'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    viewBox='0 0 34 35'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.8843 10.5269C16.313 10.0982 16.8943 9.85742 17.5006 9.85742C18.1068 9.85742 18.6882 10.0982 19.1168 10.5269C19.5116 10.9217 19.7471 11.446 19.7818 12H15.2193C15.2541 11.446 15.4895 10.9217 15.8843 10.5269ZM14.4442 13.5L14.4648 13.5003L14.4855 13.5H20.5156L20.5363 13.5003L20.5569 13.5H22.2147V23.6786C22.2147 23.8017 22.1658 23.9198 22.0787 24.0069C21.9917 24.0939 21.8735 24.1429 21.7504 24.1429H13.2504C13.1274 24.1429 13.0092 24.0939 12.9221 24.0068C12.8351 23.9198 12.7861 23.8017 12.7861 23.6786V13.5H14.4442ZM13.7175 12C13.7535 11.048 14.1475 10.1424 14.8237 9.46623C15.5336 8.75627 16.4965 8.35742 17.5006 8.35742C18.5046 8.35742 19.4675 8.75627 20.1775 9.46623L19.6471 9.99656L20.1775 9.46623C20.8536 10.1424 21.2476 11.048 21.2836 12H22.9647H24.7863C25.2005 12 25.5363 12.3358 25.5363 12.75C25.5363 13.1642 25.2005 13.5 24.7863 13.5H23.7147V23.6786C23.7147 24.1995 23.5077 24.6992 23.1394 25.0675C22.7711 25.4358 22.2714 25.6429 21.7504 25.6429H13.2504C12.7294 25.6429 12.2298 25.4358 11.8615 25.0676L11.8615 25.0676C11.4931 24.6992 11.2861 24.1995 11.2861 23.6786V13.5H10.2148C9.80063 13.5 9.46484 13.1642 9.46484 12.75C9.46484 12.3358 9.80063 12 10.2148 12H12.0361H13.7175ZM15.6792 15.6445C16.0934 15.6445 16.4292 15.9803 16.4292 16.3945V21.2535C16.4292 21.6678 16.0934 22.0035 15.6792 22.0035C15.265 22.0035 14.9292 21.6678 14.9292 21.2535V16.3945C14.9292 15.9803 15.265 15.6445 15.6792 15.6445ZM20.0718 21.2535V16.3945C20.0718 15.9803 19.736 15.6445 19.3218 15.6445C18.9076 15.6445 18.5718 15.9803 18.5718 16.3945V21.2535C18.5718 21.6678 18.9076 22.0035 19.3218 22.0035C19.736 22.0035 20.0718 21.6678 20.0718 21.2535Z'
    />
  </svg>
);

export const QuoteIcon = (props) => (
  <svg
    width='34'
    height='35'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    viewBox='0 0 34 35'
  >
    <path d='M12.5833 8.5C10.6043 8.5 9 10.1045 9 12.0834V16.0208H13.6458V14.5208H10.5V12.0834C10.5 10.9328 11.4328 10 12.5833 10V8.5ZM15.021 12.0834C15.021 10.1045 16.6253 8.5 18.6043 8.5V10C17.4538 10 16.521 10.9328 16.521 12.0834V14.5208H19.6668V16.0208H15.021V12.0834ZM18.6458 18.4375H14V19.9375H17.1458V22.3748C17.1458 23.5255 16.213 24.4583 15.0625 24.4583V25.9583C17.0415 25.9583 18.6458 24.3538 18.6458 22.3748V18.4375ZM24.6668 18.4375H20.021V19.9375H23.1668V22.3748C23.1668 23.5255 22.234 24.4583 21.0835 24.4583V25.9583C23.0625 25.9583 24.6668 24.3538 24.6668 22.3748V18.4375Z' />
  </svg>
);

export const DownloadRoundedSmallIcon = (props) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M12.5004 3.15039C7.61267 3.15039 3.65039 7.11267 3.65039 12.0004C3.65039 16.8881 7.61267 20.8504 12.5004 20.8504C17.3881 20.8504 21.3504 16.8881 21.3504 12.0004C21.3504 7.11267 17.3881 3.15039 12.5004 3.15039ZM5.15039 12.0004C5.15039 7.9411 8.4411 4.65039 12.5004 4.65039C16.5597 4.65039 19.8504 7.9411 19.8504 12.0004C19.8504 16.0597 16.5597 19.3504 12.5004 19.3504C8.4411 19.3504 5.15039 16.0597 5.15039 12.0004ZM12.5005 7.65039C12.9147 7.65039 13.2505 7.98618 13.2505 8.40039V13.7895L14.67 12.3701C14.9629 12.0772 15.4377 12.0772 15.7306 12.3701C16.0235 12.663 16.0235 13.1378 15.7306 13.4307L13.0409 16.1204C12.9045 16.2622 12.7128 16.3504 12.5005 16.3504C12.4875 16.3504 12.4747 16.3501 12.4619 16.3494C12.4023 16.3464 12.3446 16.3364 12.2895 16.3203C12.1725 16.2862 12.0622 16.223 11.97 16.1307L9.26996 13.4307C8.97707 13.1378 8.97707 12.663 9.26996 12.3701C9.56286 12.0772 10.0377 12.0772 10.3306 12.3701L11.7505 13.7899V8.40039C11.7505 7.98618 12.0863 7.65039 12.5005 7.65039Z' />
  </svg>
);

export const PlusDarkIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 4C7.44772 4 7 4.44772 7 5V7H5C4.44772 7 4 7.44771 4 8C4 8.55228 4.44772 9 5 9H7V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V9H11C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7H9V5C9 4.44772 8.55228 4 8 4Z'
      fill='#003E47'
    />
  </svg>
);

export const DownloadRoundedIcon = (props) => (
  <svg
    width='34'
    height='35'
    viewBox='0 0 34 35'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.85 8.5C11.9623 8.5 8 12.4623 8 17.35C8 22.2377 11.9623 26.2 16.85 26.2C21.7377 26.2 25.7 22.2377 25.7 17.35C25.7 12.4623 21.7377 8.5 16.85 8.5ZM9.5 17.35C9.5 13.2907 12.7907 10 16.85 10C20.9093 10 24.2 13.2907 24.2 17.35C24.2 21.4093 20.9093 24.7 16.85 24.7C12.7907 24.7 9.5 21.4093 9.5 17.35ZM16.8501 13C17.2643 13 17.6001 13.3358 17.6001 13.75V19.1391L19.0196 17.7197C19.3125 17.4268 19.7873 17.4268 20.0802 17.7197C20.3731 18.0126 20.3731 18.4874 20.0802 18.7803L17.3905 21.47C17.2541 21.6118 17.0624 21.7 16.8501 21.7C16.8372 21.7 16.8243 21.6997 16.8115 21.699C16.7519 21.696 16.6942 21.686 16.6391 21.6699C16.5221 21.6358 16.4118 21.5726 16.3196 21.4803L13.6196 18.7803C13.3267 18.4874 13.3267 18.0126 13.6196 17.7197C13.9125 17.4268 14.3873 17.4268 14.6802 17.7197L16.1001 19.1395V13.75C16.1001 13.3358 16.4359 13 16.8501 13Z'
      fill='#003E47'
      fillOpacity='0.8'
    />
  </svg>
);

export const ThreeDotsDarkIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 5.5C14 6.32843 13.3284 7 12.5 7C11.6716 7 11 6.32843 11 5.5C11 4.67157 11.6716 4 12.5 4C13.3284 4 14 4.67157 14 5.5ZM14 11.5C14 12.3284 13.3284 13 12.5 13C11.6716 13 11 12.3284 11 11.5C11 10.6716 11.6716 10 12.5 10C13.3284 10 14 10.6716 14 11.5ZM12.5 19C13.3284 19 14 18.3284 14 17.5C14 16.6716 13.3284 16 12.5 16C11.6716 16 11 16.6716 11 17.5C11 18.3284 11.6716 19 12.5 19Z'
      fill='#003E47'
      fillOpacity='0.8'
    />
  </svg>
);

export const VerificationPendingIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.47932 1.47935L7 0L5.52078 1.47922L3.50017 0.937804L2.95869 2.9586L0.938227 3.49998L1.4796 5.5204L0 7L1.4794 8.47938L0.937986 10.5L2.95898 11.0415L3.50041 13.0622L5.5208 12.5208L7 14L8.47932 12.5207L10.5002 13.0622L11.0416 11.0415L13.0626 10.5L12.521 8.47897L14 7L12.5209 5.52085L13.0624 3.49998L11.0419 2.9586L10.5004 0.937804L8.47932 1.47935ZM4 8C4.55228 8 5 7.55228 5 7C5 6.44772 4.55228 6 4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8ZM8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7ZM10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44771 6 9 6.44772 9 7C9 7.55228 9.44771 8 10 8Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export const VerifiedIcon = (props) => (
  <svg
    width='14'
    height='14'
    {...props}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 0L8.47932 1.47935L10.5004 0.937804L11.0419 2.9586L13.0624 3.49998L12.5209 5.52085L14 7L12.521 8.47897L13.0626 10.5L11.0416 11.0415L10.5002 13.0622L8.47932 12.5207L7 14L5.5208 12.5208L3.50041 13.0622L2.95898 11.0415L0.937986 10.5L1.4794 8.47938L0 7L1.4796 5.5204L0.938227 3.49998L2.95869 2.9586L3.50017 0.937804L5.52078 1.47922L7 0ZM6.33862 10.3752L10.4782 5.20081L9.10205 4.09993L6.19247 7.73687L4.83262 6.37704L3.58651 7.62315L6.33862 10.3752Z'
      fill='#49BACA'
    />
  </svg>
);
export const ThreeDotsIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='11.5' cy='6.5' r='1.5' fill='#003E47' fillOpacity='0.4' />
    <circle cx='11.5' cy='12.5' r='1.5' fill='#003E47' fillOpacity='0.4' />
    <circle cx='11.5' cy='18.5' r='1.5' fill='#003E47' fillOpacity='0.4' />
  </svg>
);

export const CalendarIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.8999 2.75C5.8999 2.33579 5.56412 2 5.1499 2C4.73569 2 4.3999 2.33579 4.3999 2.75V3.10059H3.95C2.93581 3.10059 2 3.86876 2 4.95059V7.15039V11.5506C2 12.6324 2.93581 13.4006 3.95 13.4006H12.35C13.3642 13.4006 14.3 12.6324 14.3 11.5506V7.15039V4.95059C14.3 3.86876 13.3642 3.10059 12.35 3.10059H11.8999V2.75C11.8999 2.33579 11.5641 2 11.1499 2C10.7357 2 10.3999 2.33579 10.3999 2.75V3.10059H5.8999V2.75ZM12.8 6.40039V4.95059C12.8 4.81738 12.6613 4.60059 12.35 4.60059H11.8999V4.95C11.8999 5.36421 11.5641 5.7 11.1499 5.7C10.7357 5.7 10.3999 5.36421 10.3999 4.95V4.60059H5.8999V4.95C5.8999 5.36421 5.56412 5.7 5.1499 5.7C4.73569 5.7 4.3999 5.36421 4.3999 4.95V4.60059H3.95C3.63871 4.60059 3.5 4.81738 3.5 4.95059V6.40039H12.8ZM3.5 7.90039H12.8V11.5506C12.8 11.6838 12.6613 11.9006 12.35 11.9006H3.95C3.63871 11.9006 3.5 11.6838 3.5 11.5506V7.90039Z'
      fill='#003E47'
    />
  </svg>
);

export const ArrowLeftIcon = (props) => (
  <svg
    width='34'
    height='34'
    viewBox='0 0 34 34'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M12.7758 17.5L15.6714 20.2091C16.1093 20.6188 16.1093 21.283 15.6714 21.6927C15.2335 22.1024 14.5236 22.1024 14.0857 21.6927L9.32842 17.2419C8.89053 16.8322 8.89053 16.168 9.32842 15.7583L9.32859 15.7581L14.0858 11.3073C14.5237 10.8976 15.2337 10.8976 15.6716 11.3073C16.1095 11.717 16.1095 12.3812 15.6716 12.7909L12.776 15.5H24C24.5523 15.5 25 15.9477 25 16.5C25 17.0523 24.5523 17.5 24 17.5H12.7758Z' />
  </svg>
);

export const XMarkIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    color='white'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    fillOpacity='0.5'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8285 10.8282C11.219 10.4376 11.219 9.80448 10.8285 9.41395L9.41442 7.99987L10.8282 6.58613C11.2187 6.19561 11.2187 5.56244 10.8282 5.17192C10.4376 4.78139 9.80448 4.78139 9.41395 5.17192L8.00021 6.58566L6.58586 5.17131C6.19534 4.78079 5.56217 4.78079 5.17165 5.17131C4.78112 5.56184 4.78112 6.195 5.17165 6.58552L6.586 7.99987L5.17131 9.41456C4.78079 9.80508 4.78079 10.4382 5.17131 10.8288C5.56184 11.2193 6.195 11.2193 6.58552 10.8288L8.00021 9.41409L9.41429 10.8282C9.80481 11.2187 10.438 11.2187 10.8285 10.8282Z'
      fill='currentColor'
    />
  </svg>
);

export const LogoPlaceholder = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='106'
    height='106'
    viewBox='0 0 106 106'
    fill='none'
  >
    <rect x='0.5' y='0.5' width='105' height='105' rx='52.5' fill='white' stroke='#F2F5F6' />
    <path d='M19.7588 61.833V41.7637H23.6596V58.4127H33.8921V61.833H19.7588Z' fill='#003E47' />
    <path
      d='M42.6287 62.1157C41.4227 62.1157 40.3391 61.9178 39.3781 61.5221C38.417 61.1075 37.5973 60.5422 36.9189 59.8261C36.2593 59.11 35.7505 58.2903 35.3925 57.3669C35.0344 56.4435 34.8554 55.473 34.8554 54.4554C34.8554 53.419 35.0344 52.4391 35.3925 51.5157C35.7505 50.5923 36.2593 49.7726 36.9189 49.0565C37.5973 48.3404 38.417 47.7845 39.3781 47.3887C40.3391 46.9742 41.4227 46.7669 42.6287 46.7669C43.8348 46.7669 44.9089 46.9742 45.8511 47.3887C46.8122 47.7845 47.6319 48.3404 48.3103 49.0565C48.9887 49.7726 49.4975 50.5923 49.8367 51.5157C50.1948 52.4391 50.3738 53.419 50.3738 54.4554C50.3738 55.473 50.1948 56.4435 49.8367 57.3669C49.4975 58.2903 48.9887 59.11 48.3103 59.8261C47.6508 60.5422 46.8405 61.1075 45.8794 61.5221C44.9183 61.9178 43.8348 62.1157 42.6287 62.1157ZM38.7562 54.4554C38.7562 55.3222 38.9258 56.0949 39.265 56.7733C39.6042 57.4328 40.0659 57.951 40.6501 58.3279C41.2342 58.7048 41.8938 58.8933 42.6287 58.8933C43.3448 58.8933 43.995 58.7048 44.5791 58.3279C45.1633 57.9322 45.625 57.4046 45.9642 56.745C46.3222 56.0666 46.5013 55.294 46.5013 54.4271C46.5013 53.5791 46.3222 52.8159 45.9642 52.1375C45.625 51.4591 45.1633 50.9315 44.5791 50.5546C43.995 50.1777 43.3448 49.9893 42.6287 49.9893C41.8938 49.9893 41.2342 50.1871 40.6501 50.5829C40.0659 50.9598 39.6042 51.4874 39.265 52.1658C38.9258 52.8254 38.7562 53.5886 38.7562 54.4554Z'
      fill='#003E47'
    />
    <path
      d='M58.7251 61.9178C57.7451 61.9178 56.8406 61.7294 56.0115 61.3525C55.2011 60.9567 54.4945 60.4103 53.8915 59.713C53.3073 59.0158 52.855 58.2149 52.5347 57.3103C52.2143 56.4058 52.0541 55.4447 52.0541 54.4271C52.0541 53.353 52.2237 52.3542 52.5629 51.4309C52.9021 50.5075 53.3732 49.6972 53.9763 48.9999C54.5981 48.2839 55.3236 47.7374 56.1528 47.3605C57.0008 46.9647 57.9336 46.7669 58.9512 46.7669C60.1007 46.7669 61.1089 47.0307 61.9757 47.5583C62.8426 48.0671 63.5587 48.755 64.124 49.6218V47.0213H67.4312V61.1263C67.4312 62.5962 67.0731 63.8494 66.3571 64.8858C65.6598 65.9411 64.6893 66.742 63.4456 67.2885C62.2019 67.8538 60.7885 68.1365 59.2056 68.1365C57.6038 68.1365 56.247 67.8632 55.1352 67.3167C54.0234 66.7891 53.0717 66.0353 52.2803 65.0554L54.3437 63.0485C54.9091 63.7457 55.6157 64.2922 56.4637 64.6879C57.3306 65.0837 58.2445 65.2815 59.2056 65.2815C60.0159 65.2815 60.7508 65.1308 61.4104 64.8293C62.0888 64.5466 62.6259 64.0943 63.0216 63.4725C63.4362 62.8695 63.6435 62.0874 63.6435 61.1263V59.2607C63.1535 60.1087 62.4563 60.7683 61.5517 61.2394C60.666 61.6917 59.7238 61.9178 58.7251 61.9178ZM59.9971 58.8933C60.4116 58.8933 60.8074 58.8273 61.1843 58.6954C61.5611 58.5635 61.9098 58.3845 62.2301 58.1583C62.5505 57.9322 62.8331 57.6684 63.0781 57.3669C63.3231 57.0465 63.5115 56.7262 63.6435 56.4058V52.8725C63.4173 52.2883 63.097 51.7795 62.6824 51.3461C62.2867 50.9126 61.8344 50.5829 61.3256 50.3567C60.8356 50.1118 60.3174 49.9893 59.7709 49.9893C59.1867 49.9893 58.6591 50.1118 58.188 50.3567C57.7169 50.6017 57.3117 50.9503 56.9725 51.4026C56.6333 51.836 56.3695 52.326 56.1811 52.8725C56.0115 53.4001 55.9267 53.956 55.9267 54.5402C55.9267 55.1432 56.0303 55.7086 56.2376 56.2362C56.4449 56.7639 56.7275 57.2255 57.0856 57.6213C57.4625 58.017 57.8959 58.3279 58.3859 58.5541C58.8947 58.7802 59.4317 58.8933 59.9971 58.8933Z'
      fill='#003E47'
    />
    <path
      d='M77.686 62.1157C76.48 62.1157 75.3964 61.9178 74.4354 61.5221C73.4743 61.1075 72.6546 60.5422 71.9762 59.8261C71.3166 59.11 70.8078 58.2903 70.4498 57.3669C70.0917 56.4435 69.9127 55.473 69.9127 54.4554C69.9127 53.419 70.0917 52.4391 70.4498 51.5157C70.8078 50.5923 71.3166 49.7726 71.9762 49.0565C72.6546 48.3404 73.4743 47.7845 74.4354 47.3887C75.3964 46.9742 76.48 46.7669 77.686 46.7669C78.8921 46.7669 79.9662 46.9742 80.9084 47.3887C81.8695 47.7845 82.6892 48.3404 83.3676 49.0565C84.046 49.7726 84.5548 50.5923 84.894 51.5157C85.2521 52.4391 85.4311 53.419 85.4311 54.4554C85.4311 55.473 85.2521 56.4435 84.894 57.3669C84.5548 58.2903 84.046 59.11 83.3676 59.8261C82.7081 60.5422 81.8978 61.1075 80.9367 61.5221C79.9756 61.9178 78.8921 62.1157 77.686 62.1157ZM73.8135 54.4554C73.8135 55.3222 73.9831 56.0949 74.3223 56.7733C74.6615 57.4328 75.1232 57.951 75.7074 58.3279C76.2915 58.7048 76.9511 58.8933 77.686 58.8933C78.4021 58.8933 79.0522 58.7048 79.6364 58.3279C80.2206 57.9322 80.6823 57.4046 81.0215 56.745C81.3795 56.0666 81.5586 55.294 81.5586 54.4271C81.5586 53.5791 81.3795 52.8159 81.0215 52.1375C80.6823 51.4591 80.2206 50.9315 79.6364 50.5546C79.0522 50.1777 78.4021 49.9893 77.686 49.9893C76.9511 49.9893 76.2915 50.1871 75.7074 50.5829C75.1232 50.9598 74.6615 51.4874 74.3223 52.1658C73.9831 52.8254 73.8135 53.5886 73.8135 54.4554Z'
      fill='#003E47'
    />
  </svg>
);

export const ChevronLeftIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M14 16.5L10 12.5L14 8.5'
      stroke='#003E47'
      strokeOpacity='0.4'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const SearchIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.2442 18.7145L16.4101 14.8986C17.2918 13.7103 17.8142 12.2345 17.8142 10.6328C17.8142 6.67281 14.5926 3.45117 10.6326 3.45117C6.67184 3.45047 3.4502 6.67227 3.4502 10.6323C3.4502 14.5923 6.67184 17.8139 10.6318 17.8139C12.2335 17.8139 13.6918 17.2922 14.8977 16.4098L18.7135 20.2256C18.9293 20.4414 19.1993 20.5314 19.4693 20.5314C19.7393 20.5314 20.0276 20.4232 20.2252 20.2256C20.6583 19.8121 20.6583 19.1287 20.2442 18.7146L20.2442 18.7145ZM5.59187 10.6321C5.59187 7.86045 7.86023 5.59209 10.6319 5.59209C13.4035 5.59209 15.6719 7.86045 15.6719 10.6321C15.6719 13.4037 13.4035 15.6721 10.6319 15.6721C7.86023 15.6721 5.59187 13.4221 5.59187 10.6321Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export const ThinChevronBottom = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8 9.5L12 13.5L16 9.5'
      stroke='#003E47'
      strokeOpacity='0.4'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const ChevronBottom = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      d='M10.8383 6.00006C11.0917 6.00037 11.3201 6.15319 11.417 6.38724C11.5142 6.62162 11.4605 6.891 11.2814 7.07037L8.1758 10.176C8.05892 10.2941 7.89955 10.3604 7.73361 10.3604C7.56736 10.3604 7.40799 10.2941 7.29112 10.176L4.18552 7.07037C4.00489 6.89193 3.9502 6.62224 4.04708 6.38756C4.14364 6.15287 4.37239 5.99974 4.62645 6.00005L10.8383 6.00006Z'
      fill='#003E47'
    />
  </svg>
);

export const TickmarkIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    {...props}
  >
    <path
      d='M3 9L7 13L15 5'
      stroke='#003E47'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const InfoIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M14.25 16.5H13.5C13.1022 16.5 12.7206 16.342 12.4393 16.0607C12.158 15.7794 12 15.3978 12 15V11.25C12 11.0511 11.921 10.8603 11.7803 10.7197C11.6397 10.579 11.4489 10.5 11.25 10.5H10.5'
      stroke='#003E47'
      strokeOpacity='0.8'
      strokeWidth='1.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.625 7.5C11.5255 7.5 11.4302 7.46049 11.3598 7.39016C11.2895 7.31984 11.25 7.22446 11.25 7.125C11.25 7.02554 11.2895 6.93016 11.3598 6.85984C11.4302 6.78951 11.5255 6.75 11.625 6.75'
      stroke='#003E47'
      strokeOpacity='0.8'
      strokeWidth='1.5'
    />
    <path
      d='M11.625 7.5C11.7245 7.5 11.8198 7.46049 11.8902 7.39016C11.9605 7.31984 12 7.22446 12 7.125C12 7.02554 11.9605 6.93016 11.8902 6.85984C11.8198 6.78951 11.7245 6.75 11.625 6.75'
      stroke='#003E47'
      strokeOpacity='0.8'
      strokeWidth='1.75'
    />
    <path
      d='M12 21C16.9704 21 21 16.9704 21 12C21 7.0296 16.9704 3 12 3C7.0296 3 3 7.0296 3 12C3 16.9704 7.0296 21 12 21Z'
      stroke='#003E47'
      strokeOpacity='0.8'
      strokeWidth='1.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const WatchIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_11194_1142)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.31005 3.31005C4.55404 2.06605 6.24125 1.36719 8.00052 1.36719C9.75979 1.36719 11.447 2.06605 12.691 3.31005C13.935 4.55404 14.6339 6.24125 14.6339 8.00052C14.6339 9.75979 13.935 11.447 12.691 12.691C11.447 13.935 9.75979 14.6339 8.00052 14.6339C6.24125 14.6339 4.55404 13.935 3.31005 12.691C2.06605 11.447 1.36719 9.75979 1.36719 8.00052C1.36719 6.24125 2.06605 4.55404 3.31005 3.31005ZM8.00052 2.96719C6.6656 2.96719 5.38535 3.49748 4.44142 4.44142C3.49748 5.38535 2.96719 6.6656 2.96719 8.00052C2.96719 9.33544 3.49748 10.6157 4.44142 11.5596C5.38535 12.5036 6.6656 13.0339 8.00052 13.0339C9.33544 13.0339 10.6157 12.5036 11.5596 11.5596C12.5036 10.6157 13.0339 9.33544 13.0339 8.00052C13.0339 6.6656 12.5036 5.38535 11.5596 4.44142C10.6157 3.49748 9.33544 2.96719 8.00052 2.96719Z'
        fill='#003E47'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99922 5.09961C8.44105 5.09961 8.79922 5.45778 8.79922 5.89961V7.70393L10.7409 9.96899C11.0284 10.3044 10.9896 10.8095 10.6541 11.097C10.3187 11.3846 9.81364 11.3458 9.52609 11.0103L7.39183 8.52055C7.26754 8.37555 7.19922 8.19087 7.19922 7.99989V5.89961C7.19922 5.45778 7.55739 5.09961 7.99922 5.09961Z'
        fill='#003E47'
      />
    </g>
    <defs>
      <clipPath id='clip0_11194_1142'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);

export const SettingsIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.4996 4.61668C12.5715 4.0809 11.4282 4.0809 10.5001 4.61668L6.35523 7.01014C5.42711 7.54593 4.85547 8.53593 4.85547 9.60826V14.3937C4.85547 15.466 5.42711 16.456 6.35523 16.9919L10.5001 19.3853C11.4282 19.9211 12.5715 19.9211 13.4996 19.3853L17.6445 16.9919C18.5726 16.4561 19.1442 15.4661 19.1442 14.3937V9.60826C19.1442 8.53601 18.5726 7.546 17.6445 7.01014L13.4996 4.61668ZM11.9998 14.2508C13.2423 14.2508 14.2498 13.2432 14.2498 12.0008C14.2498 10.7584 13.2423 9.75082 11.9998 9.75082C10.7574 9.75082 9.74985 10.7584 9.74985 12.0008C9.74985 13.2432 10.7574 14.2508 11.9998 14.2508Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
  </svg>
);

export const StatusStepIndicator = ({ color = "rgba(0,62,71,0.4)" }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    color={color}
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
  >
    <circle cx='6' cy='6' r='5.5' stroke='currentColor' />
    <circle cx='6' cy='6' r='3' fill='currentColor' />
  </svg>
);

export const StepGroupFinishedIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_11717_6461)'>
      <circle cx='16' cy='16' r='16' fill='#6DB333' />
      <path
        d='M10 16L14 20L22 12'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_11717_6461'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const AivoDarkLogo = () => (
  <svg width='76' height='28' viewBox='0 0 76 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M50.9485 6.49435C51.5111 6.7162 51.9066 7.09773 52.1316 7.63892C52.3567 8.18011 52.3567 8.72803 52.1316 9.28435C51.1173 11.8945 50.0825 14.5601 49.0272 17.2812C47.972 20.0023 46.9355 22.668 45.9228 25.2781C45.8103 25.5 45.5785 25.7773 45.2256 26.1118C44.8727 26.4445 44.4584 26.6109 43.9794 26.6109C43.5566 26.6109 43.1491 26.5067 42.7536 26.2983C42.3598 26.0899 42.0785 25.7504 41.9081 25.2781L35.614 9.28603C35.389 8.73139 35.3958 8.18179 35.6345 7.6406C35.8731 7.09941 36.2755 6.71788 36.838 6.49603L36.7954 6.53804C37.387 6.34308 37.9564 6.36493 38.507 6.60023C39.056 6.83721 39.4429 7.23218 39.668 7.78682L39.6254 7.7448L43.8924 18.7401C44.1737 18.0191 44.4976 17.1787 44.8642 16.2207C45.2307 15.2627 45.6177 14.2694 46.0251 13.2425C46.4343 12.2155 46.8281 11.2222 47.2082 10.2642C47.5884 9.3062 47.9191 8.46584 48.2004 7.7448L48.1152 7.78682C48.3402 7.23218 48.734 6.83553 49.2983 6.60023C49.8609 6.36493 50.4252 6.34308 50.9877 6.53804L50.9485 6.49435Z'
      fill='#49BACA'
    />
    <path
      d='M11.4459 6.49514C12.7126 6.63464 13.9025 6.99432 15.0157 7.57753C16.1272 8.16074 17.0853 8.90362 17.8883 9.80617C18.6912 10.7087 19.3237 11.7356 19.7891 12.8886C20.2545 14.0416 20.4864 15.2551 20.4864 16.5324V24.5697C20.4864 25.1243 20.275 25.6033 19.8522 26.0067C19.4294 26.41 18.9503 26.6101 18.4168 26.6101C18.219 26.6101 18.0008 26.5966 17.7621 26.568C17.5234 26.5395 17.2899 26.4638 17.0649 26.3395C16.8398 26.2151 16.6353 26.0403 16.4528 25.8184C16.2704 25.5966 16.1647 25.2907 16.1358 24.9024C15.9943 24.9579 15.742 25.0823 15.3754 25.2772C15.0089 25.4722 14.7412 25.61 14.5725 25.6941C14.2622 25.8336 13.9383 25.9579 13.6007 26.0689C13.2632 26.1798 12.9103 26.2907 12.5455 26.4016C11.8414 26.5411 11.0811 26.6101 10.2645 26.6101C8.15226 26.6101 6.26507 26.047 4.60462 24.9226C2.94245 23.7982 1.70308 22.3477 0.886488 20.5712C0.605199 19.9611 0.386988 19.3157 0.231853 18.635C0.0767179 17.9543 0 17.2535 0 16.5324C0 15.8114 0.0767179 15.1156 0.231853 14.45C0.386988 13.7844 0.605199 13.1306 0.886488 12.492C1.70308 10.6869 2.94245 9.22968 4.60462 8.11872C6.26678 7.00776 8.15226 6.45312 10.2645 6.45312C10.4895 6.45312 10.6873 6.45312 10.856 6.45312C11.0231 6.45313 11.2209 6.46657 11.4459 6.49514ZM10.2219 22.5293C11.0385 22.5293 11.821 22.3763 12.5659 22.0704C13.3126 21.7645 13.9605 21.341 14.5094 20.7998C15.0583 20.2586 15.4948 19.6266 15.8187 18.9056C16.1426 18.1829 16.3045 17.393 16.3045 16.5324C16.3045 15.7543 16.1426 14.9979 15.8187 14.2635C15.4948 13.5273 15.0515 12.8886 14.4889 12.3474C13.9264 11.8062 13.2785 11.3692 12.5455 11.0348C11.8141 10.702 11.0385 10.5356 10.2219 10.5356C9.40528 10.5356 8.63812 10.6953 7.92041 11.0146C7.2027 11.3339 6.5617 11.7709 5.99913 12.3273C5.43655 12.8819 4.9916 13.5206 4.66769 14.2433C4.34378 14.9643 4.18183 15.729 4.18183 16.5341C4.18183 17.3677 4.3506 18.1442 4.68815 18.867C5.02569 19.5897 5.48428 20.2199 6.0605 20.7628C6.63842 21.304 7.28624 21.7343 8.00395 22.0536C8.72166 22.3696 9.46153 22.5293 10.2219 22.5293Z'
      fill='#003E47'
    />
    <path
      d='M30.1422 2.16477C30.1422 2.77488 29.9308 3.2959 29.5081 3.72617C29.0853 4.15643 28.579 4.37156 27.9874 4.37156C27.3686 4.37156 26.8401 4.15643 26.4037 3.72617C25.9672 3.2959 25.749 2.77488 25.749 2.16477C25.749 1.58156 25.9672 1.07566 26.4037 0.645398C26.8401 0.215133 27.3686 0 27.9874 0C28.579 0 29.0853 0.215133 29.5081 0.645398C29.9308 1.07398 30.1422 1.58156 30.1422 2.16477ZM27.9874 26.6109C27.3686 26.6109 26.8401 26.4025 26.4037 25.9857C25.9672 25.5689 25.749 25.0411 25.749 24.4024V8.74481C25.749 8.13471 25.9672 7.62041 26.4037 7.20359C26.8401 6.78677 27.3686 6.57836 27.9874 6.57836C28.55 6.57836 29.0512 6.78677 29.4876 7.20359C29.924 7.62041 30.1422 8.13303 30.1422 8.74481V24.4041C30.1422 25.0428 29.924 25.5705 29.4876 25.9874C29.0495 26.4025 28.55 26.6109 27.9874 26.6109Z'
      fill='#003E47'
    />
    <path
      d='M65.6737 6.87109C67.0528 6.87109 68.357 7.14169 69.581 7.68288C70.8068 8.22408 71.8825 8.95351 72.8133 9.86951C73.7424 10.7855 74.4823 11.8544 75.0312 13.0763C75.5802 14.2982 75.8546 15.6025 75.8546 16.9907C75.8546 18.379 75.587 19.6765 75.0517 20.885C74.5164 22.0934 73.7919 23.1556 72.8764 24.0716C71.9609 24.9876 70.8835 25.7103 69.6441 26.2381C68.4047 26.7658 67.0665 27.0297 65.6311 27.0297C64.2229 27.0297 62.9 26.7793 61.6606 26.2801C60.4212 25.7809 59.337 25.0784 58.4079 24.1775C57.4788 23.275 56.7457 22.2127 56.2121 20.9909C55.6768 19.769 55.4092 18.4362 55.4092 16.9924C55.4092 15.6041 55.67 14.3066 56.19 13.0982C56.7116 11.8897 57.4362 10.8359 58.3653 9.93337C59.2944 9.03082 60.3854 8.30979 61.6385 7.7686C62.8932 7.22405 64.2383 6.92656 65.6737 6.87109ZM65.6311 10.9939C64.8145 10.9939 64.032 11.1469 63.287 11.4527C62.5403 11.7586 61.8925 12.1822 61.3435 12.7234C60.7946 13.2646 60.3582 13.8965 60.0343 14.6176C59.7103 15.3403 59.5484 16.1302 59.5484 16.9907C59.5484 17.7958 59.7103 18.5656 60.0343 19.3017C60.3582 20.0379 60.7946 20.6833 61.3435 21.2379C61.8925 21.7926 62.5403 22.2312 63.287 22.5506C64.0337 22.8699 64.8145 23.0296 65.6311 23.0296C66.4476 23.0296 67.2301 22.8699 67.9751 22.5506C68.7218 22.2312 69.3696 21.7942 69.9186 21.2379C70.4675 20.6833 70.904 20.0379 71.2279 19.3017C71.5518 18.5656 71.7137 17.7958 71.7137 16.9907C71.6285 15.352 71.0386 13.9705 69.939 12.8461C68.8412 11.7217 67.4177 11.1032 65.672 10.9922H65.6311V10.9939Z'
      fill='#003E47'
    />
  </svg>
);

export const IdentityIcon = () => (
  <svg width='124' height='86' viewBox='0 0 124 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1_12808_87450' fill='white'>
      <path d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z' />
    </mask>
    <path
      d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z'
      fill='#F2F5F6'
    />
    <path
      d='M16 16H108H16ZM108 57C108 64.732 101.732 71 94 71H30C22.268 71 16 64.732 16 57C16 63.6274 21.8203 69 29 69H95C102.18 69 108 63.6274 108 57ZM16 70V16V70ZM108 16V70V16Z'
      fill='#003E47'
      fillOpacity='0.1'
      mask='url(#path-1-inside-1_12808_87450)'
    />
    <path
      d='M50.9153 46.8417C52.0291 48.2236 52.6323 49.9406 52.6323 51.7092C52.6323 53.0653 51.7506 54.2667 50.4564 54.6689C45.2744 56.2777 39.7261 56.2777 34.5441 54.6689C33.2499 54.2667 32.3682 53.0653 32.3682 51.7092C32.3682 49.9406 32.9714 48.2237 34.0852 46.8417C35.1732 45.4856 36.7871 44.6503 38.5247 44.542C40.9997 45.9032 44.0007 45.9032 46.4757 44.542C48.2133 44.6503 49.8272 45.4856 50.9152 46.8417H50.9153Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <path
      d='M48.6875 37.3125C48.6875 40.7297 45.9173 43.5 42.5 43.5C39.0827 43.5 36.3125 40.7298 36.3125 37.3125C36.3125 33.8952 39.0827 31.125 42.5 31.125C45.9173 31.125 48.6875 33.8952 48.6875 37.3125Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <rect x='61' y='34' width='24' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
    <rect x='61' y='41' width='32' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
  </svg>
);

export const IdentityVerificationNeededIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='124' height='86' viewBox='0 0 124 86' fill='none'>
    <path d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z' />
    <path
      d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z'
      fill='#F2F5F6'
    />
    <path
      d='M16 16H108H16ZM108 57C108 64.732 101.732 71 94 71H30C22.268 71 16 64.732 16 57C16 63.6274 21.8203 69 29 69H95C102.18 69 108 63.6274 108 57ZM16 70V16V70ZM108 16V70V16Z'
      fill='#003E47'
      fillOpacity='0.1'
    />
    <path
      d='M50.9153 46.8417C52.0291 48.2236 52.6323 49.9406 52.6323 51.7092C52.6323 53.0653 51.7506 54.2667 50.4564 54.6689C45.2744 56.2777 39.7261 56.2777 34.5441 54.6689C33.2499 54.2667 32.3682 53.0653 32.3682 51.7092C32.3682 49.9406 32.9714 48.2237 34.0852 46.8417C35.1732 45.4856 36.7871 44.6503 38.5247 44.542C40.9997 45.9032 44.0007 45.9032 46.4757 44.542C48.2133 44.6503 49.8272 45.4856 50.9152 46.8417H50.9153Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <path
      d='M48.6875 37.3125C48.6875 40.7297 45.9173 43.5 42.5 43.5C39.0827 43.5 36.3125 40.7298 36.3125 37.3125C36.3125 33.8952 39.0827 31.125 42.5 31.125C45.9173 31.125 48.6875 33.8952 48.6875 37.3125Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <rect x='61' y='34' width='24' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
    <rect x='61' y='41' width='32' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
    <circle cx='105.5' cy='69.5' r='16.5' fill='#D6B029' />
    <rect x='103' y='72' width='12' height='5' rx='1' transform='rotate(-90 103 72)' fill='white' />
    <rect x='103' y='80' width='5' height='5' rx='1' transform='rotate(-90 103 80)' fill='white' />
  </svg>
);

export const IdentityVerifiedIcon = () => (
  <svg width='124' height='86' viewBox='0 0 124 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1_12808_89430' fill='white'>
      <path d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z' />
    </mask>
    <path
      d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z'
      fill='#F2F5F6'
    />
    <path
      d='M16 16H108H16ZM108 57C108 64.732 101.732 71 94 71H30C22.268 71 16 64.732 16 57C16 63.6274 21.8203 69 29 69H95C102.18 69 108 63.6274 108 57ZM16 70V16V70ZM108 16V70V16Z'
      fill='#003E47'
      fillOpacity='0.1'
      mask='url(#path-1-inside-1_12808_89430)'
    />
    <path
      d='M50.9153 46.8417C52.0291 48.2236 52.6323 49.9406 52.6323 51.7092C52.6323 53.0653 51.7506 54.2667 50.4564 54.6689C45.2744 56.2777 39.7261 56.2777 34.5441 54.6689C33.2499 54.2667 32.3682 53.0653 32.3682 51.7092C32.3682 49.9406 32.9714 48.2237 34.0852 46.8417C35.1732 45.4856 36.7871 44.6503 38.5247 44.542C40.9997 45.9032 44.0007 45.9032 46.4757 44.542C48.2133 44.6503 49.8272 45.4856 50.9152 46.8417H50.9153Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <path
      d='M48.6875 37.3125C48.6875 40.7297 45.9173 43.5 42.5 43.5C39.0827 43.5 36.3125 40.7298 36.3125 37.3125C36.3125 33.8952 39.0827 31.125 42.5 31.125C45.9173 31.125 48.6875 33.8952 48.6875 37.3125Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <rect x='61' y='34' width='24' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
    <rect x='61' y='41' width='32' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
    <circle cx='105.5' cy='69.5' r='16.5' fill='#6DB333' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M103.01 72.0492L111.192 63.5499C111.713 63.0085 112.58 62.9921 113.122 63.513L114.38 64.7254C114.921 65.2463 114.938 66.1134 114.417 66.6548L104.025 77.4483C103.76 77.7231 103.434 77.8634 103.052 77.8667C102.671 77.8692 102.342 77.7338 102.074 77.4631L96.5973 71.9376C96.0682 71.4035 96.0723 70.5373 96.6063 70.0082L97.8475 68.7785C98.3815 68.2495 99.2477 68.2535 99.7768 68.7876L103.011 72.0508L103.01 72.0492Z'
      fill='white'
    />
  </svg>
);

export const CopyIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    {...props}
    viewBox='0 0 24 24'
  >
    <path
      d='M20 8H10C8.89543 8 8 8.89543 8 10V20C8 21.1046 8.89543 22 10 22H20C21.1046 22 22 21.1046 22 20V10C22 8.89543 21.1046 8 20 8Z'
      stroke='#0F8594'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 16C2.9 16 2 15.1 2 14V4C2 2.9 2.9 2 4 2H14C15.1 2 16 2.9 16 4'
      stroke='#0F8594'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IdentityRejectedIcon = () => (
  <svg width='124' height='86' viewBox='0 0 124 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1_12808_89524' fill='white'>
      <path d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z' />
    </mask>
    <path
      d='M16 29C16 21.8203 21.8203 16 29 16H95C102.18 16 108 21.8203 108 29V57C108 64.1797 102.18 70 95 70H29C21.8203 70 16 64.1797 16 57V29Z'
      fill='#F2F5F6'
    />
    <path
      d='M16 16H108H16ZM108 57C108 64.732 101.732 71 94 71H30C22.268 71 16 64.732 16 57C16 63.6274 21.8203 69 29 69H95C102.18 69 108 63.6274 108 57ZM16 70V16V70ZM108 16V70V16Z'
      fill='#003E47'
      fillOpacity='0.1'
      mask='url(#path-1-inside-1_12808_89524)'
    />
    <path
      d='M50.9153 46.8417C52.0291 48.2236 52.6323 49.9406 52.6323 51.7092C52.6323 53.0653 51.7506 54.2667 50.4564 54.6689C45.2744 56.2777 39.7261 56.2777 34.5441 54.6689C33.2499 54.2667 32.3682 53.0653 32.3682 51.7092C32.3682 49.9406 32.9714 48.2237 34.0852 46.8417C35.1732 45.4856 36.7871 44.6503 38.5247 44.542C40.9997 45.9032 44.0007 45.9032 46.4757 44.542C48.2133 44.6503 49.8272 45.4856 50.9152 46.8417H50.9153Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <path
      d='M48.6875 37.3125C48.6875 40.7297 45.9173 43.5 42.5 43.5C39.0827 43.5 36.3125 40.7298 36.3125 37.3125C36.3125 33.8952 39.0827 31.125 42.5 31.125C45.9173 31.125 48.6875 33.8952 48.6875 37.3125Z'
      fill='#003E47'
      fillOpacity='0.4'
    />
    <rect x='61' y='34' width='24' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
    <rect x='61' y='41' width='32' height='4' rx='1' fill='#003E47' fillOpacity='0.2' />
    <circle cx='105.5' cy='69.5' r='16.5' fill='#FF5454' />
    <rect
      x='101.001'
      y='62'
      width='16.9702'
      height='4.24256'
      rx='1'
      transform='rotate(45 101.001 62)'
      fill='white'
    />
    <rect
      x='113'
      y='65'
      width='16.9702'
      height='4.24256'
      rx='1'
      transform='rotate(135 113 65)'
      fill='white'
    />
  </svg>
);

export const CheckmarkIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='currentColor'
      {...props}
      viewBox='0 0 24 24'
    >
      <path d='M17.66 7.066c-.188.057-.432.292-3.93 3.786l-3.729 3.726-1.731-1.725c-1.654-1.65-1.74-1.728-1.947-1.789-.27-.08-.374-.08-.633-.003-.279.083-.546.35-.629.629-.077.258-.077.362.002.633.061.209.141.294 2.23 2.384 2.09 2.089 2.175 2.169 2.384 2.23.272.079.374.079.646 0 .212-.062.318-.165 4.384-4.23 4.065-4.066 4.168-4.172 4.23-4.384.079-.271.079-.375.002-.633a1.004 1.004 0 0 0-.629-.629c-.25-.075-.39-.073-.65.005' />
    </svg>
  );
};

export const CheckmarkGreenIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='8' cy='8' r='6' fill='#6DB333' stroke='#6DB333' strokeWidth='4' />
    <path
      d='M12.0916 5.23653C11.699 4.83305 11.0508 4.83308 10.6582 5.23659L6.84251 9.15831L5.34198 7.61623C4.94931 7.21268 4.30102 7.21275 3.90844 7.61638L3.67813 7.85316C3.3006 8.24131 3.30059 8.85944 3.67811 9.2476L6.12457 11.7631C6.51717 12.1667 7.1655 12.1668 7.55818 11.7632L12.3215 6.86743C12.6992 6.47922 12.6991 5.86086 12.3214 5.47268L12.0916 5.23653Z'
      fill='white'
    />
  </svg>
);

export const ChevronRight = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 16.5L14 12.5L10 8.5'
      stroke='#003E47'
      strokeOpacity='0.8'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const GreenTickmark = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='35' height='26' viewBox='0 0 35 26' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.1119 1.14315C34.4007 1.4516 34.6258 1.81396 34.7744 2.20949C34.9229 2.60503 34.9921 3.02597 34.9778 3.44825C34.9636 3.87053 34.8662 4.28585 34.6913 4.67046C34.5164 5.05507 34.2673 5.40142 33.9584 5.6897L13.9142 24.4325C13.3039 25.0029 12.4955 25.3133 11.6602 25.2979C10.825 25.2825 10.0285 24.9426 9.43963 24.3501L1.88094 16.7565C1.58315 16.4573 1.34721 16.1024 1.18657 15.7121C1.02594 15.3218 0.943758 14.9036 0.944729 14.4815C0.945701 14.0594 1.0298 13.6416 1.19223 13.252C1.35466 12.8624 1.59224 12.5086 1.8914 12.2108C2.19056 11.913 2.54544 11.6771 2.93579 11.5165C3.32613 11.3558 3.7443 11.2736 4.1664 11.2746C4.58851 11.2756 5.00629 11.3597 5.39589 11.5221C5.78549 11.6845 6.13929 11.9221 6.43707 12.2213L11.8001 17.6078L29.5605 0.993273C29.8687 0.704164 30.2309 0.478662 30.6263 0.329674C31.0218 0.180685 31.4427 0.111134 31.865 0.124998C32.2874 0.138861 32.7029 0.235868 33.0877 0.410468C33.4725 0.585067 33.8233 0.834448 34.1119 1.14315Z'
      fill='#6DB333'
    />
  </svg>
);

export const RedCross = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.3142 5.55687C29.4858 4.3853 29.4858 2.4858 28.3142 1.31423C27.1427 0.142655 25.2432 0.142655 24.0716 1.31423L14.9995 10.3863L5.92796 1.31468C4.75639 0.143107 2.85689 0.143107 1.68532 1.31468C0.513748 2.48625 0.513748 4.38575 1.68532 5.55732L10.7569 14.6289L1.44417 23.9416C0.272597 25.1132 0.272597 27.0127 1.44417 28.1843C2.61574 29.3559 4.51524 29.3559 5.68681 28.1843L14.9995 18.8715L24.3127 28.1847C25.4843 29.3563 27.3838 29.3563 28.5554 28.1847C29.727 27.0132 29.727 25.1137 28.5554 23.9421L19.2422 14.6289L28.3142 5.55687Z'
      fill='#FF5454'
    />
  </svg>
);

export const StatusArrow = (props) => (
  <div className='arrow' {...props}>
    <div className='left'></div>
    <div className='right'></div>
  </div>
);

export const InProgressStatusArrow = (props) => (
  <StatusArrow {...props} style={{ "--background-color": "#6DB333", opacity: 0.2 }} />
);

export const FinishedStatusArrow = (props) => (
  <StatusArrow {...props} style={{ "--background-color": "#6DB333" }} />
);

export const NotStartedStatusArrow = (props) => (
  <StatusArrow {...props} style={{ "--background-color": "#003E47", opacity: 0.1 }} />
);

export const PenIcon = ({ ...props }) => (
  <svg
    width='24'
    height='24'
    fill='currentColor'
    {...props}
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16.508 7.46927C15.6973 6.65865 14.379 6.65776 13.5683 7.46841L12.8167 8.22002L15.7789 11.1822L16.5305 10.4306C17.342 9.62083 17.3412 8.30247 16.508 7.46927Z' />
    <path d='M6.48005 14.5566L6.48092 17.5188L9.44312 17.5197L15.0388 11.924L12.0757 8.96094L6.48005 14.5566Z' />
  </svg>
);

export const PenDarkIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.0306 6.96938C14.8413 6.15874 16.1596 6.15963 16.9702 6.97025C17.8034 7.80344 17.8043 9.12181 16.9928 9.93156L16.2412 10.6832L13.279 7.72099L14.0306 6.96938ZM6.94326 17.0198L6.94239 14.0576L12.5381 8.46191L15.5012 11.425L9.90546 17.0207L6.94326 17.0198Z'
      fill='#003E47'
      fillOpacity='0.8'
    />
  </svg>
);

export const CloseDarkIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.7559 8.42259C17.0814 8.09715 17.0814 7.56951 16.7559 7.24408C16.4305 6.91864 15.9028 6.91864 15.5774 7.24408L12 10.8215L8.42259 7.24408C8.09715 6.91864 7.56951 6.91864 7.24408 7.24408C6.91864 7.56951 6.91864 8.09715 7.24408 8.42259L10.8215 12L7.24408 15.5774C6.91864 15.9028 6.91864 16.4305 7.24408 16.7559C7.56951 17.0814 8.09715 17.0814 8.42259 16.7559L12 13.1785L15.5774 16.7559C15.9028 17.0814 16.4305 17.0814 16.7559 16.7559C17.0814 16.4305 17.0814 15.9028 16.7559 15.5774L13.1785 12L16.7559 8.42259Z'
      fill='#003E47'
      fillOpacity='0.8'
    />
  </svg>
);

export const DragIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6 6H18'
      stroke='#003E47'
      strokeOpacity='0.4'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M6 10H18'
      stroke='#003E47'
      strokeOpacity='0.4'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M6 14H18'
      stroke='#003E47'
      strokeOpacity='0.4'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M6 18H18'
      stroke='#003E47'
      strokeOpacity='0.4'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

export const PlusCircleIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5V7H11C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9H9V11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11V9H5C4.44772 9 4 8.55228 4 8C4 7.44771 4.44772 7 5 7H7V5Z'
      fill='#003E47'
      fillOpacity='0.2'
    />
  </svg>
);

export const PrivateIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_13322_22496)'>
      <path
        d='M3.99984 7.25008H4.24984V5.75008C4.24984 3.68192 5.93112 2 7.99992 2C10.0681 2 11.75 3.68192 11.75 5.75008V7.25008H12C12.5519 7.25008 13 7.69821 13 8.25008V13.2501C13 13.802 12.5519 14.2501 12 14.2501H4C3.44813 14.2501 3 13.802 3 13.2501V8.25008C3 7.69821 3.44813 7.25008 4 7.25008H3.99984ZM7.49984 10.6114V12.0001C7.49984 12.1376 7.61234 12.2501 7.74984 12.2501H8.24984C8.38797 12.2501 8.49984 12.1376 8.49984 12.0001V10.6114C8.79734 10.4376 8.99984 10.1201 8.99984 9.75011C8.99984 9.19824 8.55171 8.75011 7.99984 8.75011C7.44797 8.75011 6.99984 9.19824 6.99984 9.75011C6.99984 10.1189 7.20171 10.4382 7.49984 10.6114ZM5.74976 7.25008H10.2498V5.75008C10.2498 4.50946 9.24038 3.50016 7.99984 3.50016C6.75984 3.50016 5.74992 4.50954 5.74992 5.75008L5.74976 7.25008Z'
        fill='#003E47'
        fillOpacity='0.8'
      />
    </g>
    <defs>
      <clipPath id='clip0_13322_22496'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const PublicIcon = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.77025 5.23077C4.77025 3.44646 6.21631 2 8.0001 2C9.7839 2 11.23 3.44646 11.23 5.23077C11.23 7.01508 9.7839 8.46154 8.0001 8.46154C6.21631 8.46154 4.77025 7.01508 4.77025 5.23077ZM8 9.38462C5.31208 9.38462 3.00866 11.0267 2.03555 13.3609C1.97616 13.5033 1.99189 13.666 2.07746 13.7944C2.16303 13.9228 2.30711 14 2.46141 14H13.5386C13.6929 14 13.837 13.9228 13.9225 13.7944C14.0081 13.666 14.0239 13.5033 13.9644 13.3609C12.9913 11.0267 10.6879 9.38462 8 9.38462Z'
      fill='#003E47'
      fillOpacity='0.8'
    />
  </svg>
);

export const PlusRoundedIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16' cy='16' r='15.5' stroke='#003E47' strokeOpacity='0.2' strokeDasharray='2 2' />
    <g clipPath='url(#clip0_15078_12845)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.0684 16.999C23.6207 16.999 24.0684 16.5513 24.0684 15.999C24.0684 15.4467 23.6207 14.999 23.0684 14.999L17.0005 14.999L17.0005 8.93106C17.0005 8.37877 16.5528 7.93106 16.0005 7.93106C15.4482 7.93106 15.0005 8.37877 15.0005 8.93106L15.0005 14.999L8.93253 14.999C8.38025 14.999 7.93253 15.4467 7.93253 15.999C7.93253 16.5513 8.38025 16.999 8.93253 16.999L15.0005 16.999V23.067C15.0005 23.6193 15.4482 24.067 16.0005 24.067C16.5528 24.067 17.0005 23.6193 17.0005 23.067V16.999L23.0684 16.999Z'
        fill='#003E47'
        fillOpacity='0.6'
      />
    </g>
    <defs>
      <clipPath id='clip0_15078_12845'>
        <rect width='14' height='14' fill='white' transform='translate(16 6.10059) rotate(45)' />
      </clipPath>
    </defs>
  </svg>
);

export const TrashGrayIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.4195 5.16947C10.8481 4.74082 11.4295 4.5 12.0357 4.5C12.6419 4.5 13.2233 4.74082 13.652 5.16947C14.0468 5.56426 14.2822 6.0886 14.3169 6.64258H9.75448C9.78922 6.0886 10.0247 5.56426 10.4195 5.16947ZM8.97934 8.14258L9 8.14286L9.02066 8.14258H15.0508L15.0714 8.14286L15.0921 8.14258H16.7499V18.3211C16.7499 18.4442 16.7009 18.5624 16.6139 18.6494C16.5268 18.7365 16.4087 18.7854 16.2856 18.7854H7.78557C7.66252 18.7854 7.54438 18.7365 7.45723 18.6494C7.37023 18.5624 7.32129 18.4443 7.32129 18.3211V8.14258H8.97934ZM8.2527 6.64258C8.28869 5.69055 8.68266 4.78496 9.35881 4.10881C10.0688 3.39885 11.0317 3 12.0357 3C13.0397 3 14.0027 3.39885 14.7126 4.10881L14.1823 4.63914L14.7126 4.10881C15.3888 4.78496 15.7827 5.69055 15.8187 6.64258H17.4999H19.3214C19.7356 6.64258 20.0714 6.97836 20.0714 7.39258C20.0714 7.80679 19.7356 8.14258 19.3214 8.14258H18.2499V18.3211C18.2499 18.8421 18.0428 19.3418 17.6745 19.7101C17.3062 20.0784 16.8065 20.2854 16.2856 20.2854H7.78557C7.26453 20.2854 6.76497 20.0784 6.39667 19.7102L6.39665 19.7101C6.02822 19.3418 5.82129 18.8421 5.82129 18.3211V8.14258H4.75C4.33579 8.14258 4 7.80679 4 7.39258C4 6.97836 4.33579 6.64258 4.75 6.64258H6.57129H8.2527ZM10.2144 10.2871C10.6286 10.2871 10.9644 10.6229 10.9644 11.0371V15.8961C10.9644 16.3103 10.6286 16.6461 10.2144 16.6461C9.80014 16.6461 9.46436 16.3103 9.46436 15.8961V11.0371C9.46436 10.6229 9.80014 10.2871 10.2144 10.2871ZM14.6069 15.8961V11.0371C14.6069 10.6229 14.2711 10.2871 13.8569 10.2871C13.4427 10.2871 13.1069 10.6229 13.1069 11.0371V15.8961C13.1069 16.3103 13.4427 16.6461 13.8569 16.6461C14.2711 16.6461 14.6069 16.3103 14.6069 15.8961Z'
      fill='#003E47'
      fillOpacity='0.8'
    />
  </svg>
);

export const UploadIcon = () => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.6918 7.32771L15.2437 8.87958L15.2438 8.87974C15.6275 9.26349 16.2357 9.26349 16.6194 8.87974C17.0032 8.49536 17.0032 7.88723 16.6194 7.50349L13.4037 4.28781C13.0194 3.90406 12.4112 3.90406 12.0275 4.28781L8.81116 7.48717C8.42741 7.87155 8.42741 8.47968 8.81116 8.86342C9.00303 9.0553 9.24303 9.15155 9.49929 9.15155C9.75554 9.15155 9.99554 9.05529 10.1874 8.86342L11.7393 7.31155V14.1759C11.7393 14.7203 12.1718 15.1521 12.7155 15.1521C13.2599 15.1521 13.6918 14.7196 13.6918 14.1759V7.32771ZM7.89997 14.6243C7.89997 14.08 7.46747 13.6481 6.92374 13.6481C6.37936 13.6481 5.94749 14.08 5.94749 14.6237V18.24C5.94749 18.7844 6.38 19.2163 6.92374 19.2163H18.5237C19.0681 19.2163 19.5 18.7838 19.5 18.24V14.6237C19.5 14.0793 19.0675 13.6475 18.5237 13.6475C17.9794 13.6475 17.5475 14.08 17.5475 14.6237V17.2637L7.89997 17.2643V14.6243Z'
      fill='white'
    />
  </svg>
);
