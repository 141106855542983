import React, { memo, useEffect, useState } from "react";
import { getFileExtensionFromUrl } from "../../utils/general";
import DocViewer, {
  // DocViewerRenderers,
  BMPRenderer,
  TIFFRenderer,
  TXTRenderer,
  // MSDocRenderer,
  JPGRenderer,
  CSVRenderer,
  PNGRenderer,
  PDFRenderer,
  // DocRenderer,
  HTMLRenderer,
  GIFRenderer,
  VideoRenderer,
  WebPRenderer,
} from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import classes from "./FilePreview.module.scss";
import FilePreviewError from "./FilePreviewError";
import { Loading } from "../ui-components";

interface FilePreviewProps {
  key?: string | number;
  url: string;
}

const FilePreview = ({ key, url }: FilePreviewProps) => {
  const extension = getFileExtensionFromUrl(url).toLowerCase();

  return (
    <DocViewer
      key={key}
      pluginRenderers={[
        BMPRenderer,
        TIFFRenderer,
        TXTRenderer,
        JPGRenderer,
        CSVRenderer,
        PNGRenderer,
        PDFRenderer,
        HTMLRenderer,
        GIFRenderer,
        VideoRenderer,
        WebPRenderer,
      ]}
      documents={[{ uri: url, fileType: extension }]}
      className={classes.root}
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
        },
        pdfVerticalScrollByDefault: true,
        loadingRenderer: {
          overrideComponent: () => <Loading position='absoluteCenter' className={classes.loader} />,
        },
        noRenderer: {
          overrideComponent: FilePreviewError,
        },
      }}
    />
  );
};

export default memo(FilePreview, (prev, next) => {
  const prevURL = new URL(prev.url);
  const nextURL = new URL(next.url);
  return prevURL.pathname === nextURL.pathname;
});
