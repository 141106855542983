import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { Button } from "../../../../../components/ui-components";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import { FC } from "react";
import SignerInfo from "../SignerInfo";
import styles from "./Signer.module.scss";

interface Coordinates {
  x: number;
  y: number;
}

interface DocumentAccess {
  id: string;
  coordinates?: Coordinates;
  signatureWidth?: number;
}

interface SignerProps {
  signer: DocumentAccess;
  index: number;
  updateSignerPosition: (index: number, coordinates: Coordinates) => void;
  onClose: (id: string) => void;
  isEditable?: boolean;
}

const Signer: FC<SignerProps> = ({
  signer,
  index,
  updateSignerPosition,
  onClose,
  isEditable = true,
}) => {
  const [{ x, y }, api] = useSpring(() => ({
    x: signer.coordinates?.x || 0,
    y: signer.coordinates?.y || 0,
    config: { tension: 300, friction: 20 },
  }));

  const bind = useDrag(
    ({ movement: [mx, my], down }) => {
      if (!isEditable) return; // Prevent moving if not editable

      const x = (signer.coordinates?.x || 0) + mx;
      const y = (signer.coordinates?.y || 0) + my;
      api.start({ x, y });

      if (!down) {
        updateSignerPosition(index, { x, y });
      }
    },
    {
      bounds: () => (document.querySelector(".react-pdf__Document") as HTMLElement) || undefined,
    }
  );

  return (
    <animated.div
      id={signer.id}
      style={{
        x,
        y,
        width: signer.signatureWidth ? `${signer.signatureWidth * 100}%` : "33%",
        cursor: isEditable ? "grab" : "default",
        resize: isEditable ? "horizontal" : "none",
      }}
      className={styles.animatedDiv}
    >
      <SignerInfo small isSelected signer={signer} />
      {isEditable && (
        <div
          {...bind()}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "calc(100% - 4px)",
            height: "100%",
            zIndex: 10,
          }}
        />
      )}
      {isEditable && (
        <Button color='gray' className={styles.close} onClick={() => onClose(signer.id)}>
          <CloseIcon />
        </Button>
      )}
    </animated.div>
  );
};

export default Signer;
