import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import DocumentOption from "../../../../../Admin/components/DocumentOption";
import { useDocuments } from "../../../../../hooks/services/useDocuments";
import AttachDocuments from "../../../../AttachDocuments";
import ToolbarButton from "../../ToolbarButton";
import { Document } from "../../../../../types/document";

import { ReactComponent as Icon } from "../../../../../assets/icons/textEditor/link.svg";

import styles from "./AttachDocumentButton.module.scss";

type AttachDocumentButtonProps = {
  documents: Document[];
  selectedDocuments: Document[];

  setIsUploadingDocuments: (isUploading: boolean) => void;
  onAttachDocuments: (documents: Document[]) => void;
  onClick?: () => void;
};

export default function AttachDocumentButton({
  documents,
  selectedDocuments,
  onAttachDocuments,
  setIsUploadingDocuments,
  onClick,
}: AttachDocumentButtonProps) {
  const { id: auditId } = useParams<{ id: string }>();
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });
  const { createMultipleDocuments } = useDocuments();

  const handleAddFile = async (files: Array<File>) => {
    console.log("handle add file called");
    setIsUploadingDocuments(true);
    createMultipleDocuments(
      {
        documents: files,
        type: "evidence",
        auditId,
      },
      {
        onSuccess: (res) => {
          console.log("handle add file on success called");
          onAttachDocuments(res.documents);
        },
        onError: () => {
          console.log("handle add file on error called");
          setIsUploadingDocuments(false);
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <AttachDocuments
        placeholder='Search files'
        options={documents}
        values={selectedDocuments}
        onChange={onAttachDocuments}
        optionComponent={({
          id,
          name,
          document,
        }: {
          id: string;
          name: string;
          document: string;
        }) => <DocumentOption document={document} name={name} key={id} />}
        onAddFile={handleAddFile}
      >
        <ToolbarButton Icon={Icon} tooltipTitle={t("attach_file")} onClick={onClick} />
      </AttachDocuments>
    </div>
  );
}
