import { Space } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { createPortal } from "react-dom";
import { Trans, useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FileExtension from "../../../Admin/components/FileExtension";

import {
  downloadDocument,
  oneDocument,
  signDocument,
  syncDocument,
} from "../../../api/documentApi";

import arrows from "../../../assets/admin/arrows.svg";
import { CalendarIcon, DownloadRoundedSmallIcon } from "../../../assets/icons";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as UserAddIcon } from "../../../assets/icons/user-add.svg";
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import EditableDocumentName from "../../../components/EditableDocumentName";
import FilePreview from "../../../components/FilePreview/FilePreview";
import SignaturePreviewPopup from "../../../components/popups/SignaturePreviewPopup";
import StartSigningPopup from "../../../components/StartSigningPopup";
import { Button } from "../../../components/ui-components";
import toFullName from "../../../consts/toFullName";
import { DocumentSignaturesStatus } from "../../../enums";
import { useDocuments } from "../../../hooks/services/useDocuments";
import { downloadFileFromURL } from "../../../utils/downloadFileFromURL";
import DocumentSharePopup from "./components/DocumentSharePopup";

import "./pdfOpen.css";
import DocumentViewComments from "./components/DocumentViewComments";
import DocumentViewSignatures from "./components/DocumentViewSignatures";
import Signer from "./components/Signer";
import styles from "./DocumentView.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const recalculateCoordinates = (percentageX, percentageY, pageWidth, pageHeight, pageOffsetY) => {
  const newX = percentageX * pageWidth;
  const newY = percentageY * pageHeight + pageOffsetY;

  return { newX, newY };
};

const DocumentView = () => {
  const history = useHistory();
  const { id: idAudit, documentId: idDocument } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { updateDocumentSignatures } = useDocuments();

  const selector = useSelector((state) => state.user.user_role?.role);
  const user = useSelector((state) => state.user.user_data);

  const mousePosition = useRef({ x: 0, y: 0 });
  const dragOffsetPercentage = useRef({ offsetXPercentage: 0, offsetYPercentage: 0 });
  const intervalIDRef = useRef(null);
  const pdfBlockRef = useRef(null);

  const [isPdfRendered, setIsPdfRendered] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [commentAdd, setCommentAdd] = useState(false);
  const [isSignPopupOpen, setIsSignPopupOpen] = useState(false);
  const [isSignaturePopupOpen, setIsSignaturePopupOpen] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [mySignerStatus, setMySignerStatus] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showSignatures, setShowSignatures] = useState(false);
  const [newSignatures, setNewSignatures] = useState([]);
  const [deletedSignatures, setDeletedSignatures] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [clonedSigner, setClonedSigner] = useState(null);

  const unfinishedSigners = documentData?.signers?.filter((signer) => signer?.status !== "signed");
  const inProgressSigner = unfinishedSigners?.find((signer) => signer?.status === "in_progress");
  const hasInProgressSigners = !!inProgressSigner;
  const signerUser = (inProgressSigner?.teamMember || inProgressSigner?.auditorMember?.member)
    ?.user;
  const mySigner = unfinishedSigners?.find(
    (signer) =>
      signer.teamMember?.user.email === user.email ||
      signer.auditorMember?.member.user.email === user.email
  );
  const isUnsigned = mySigner?.status === "unsigned" || mySigner?.status === "failed";
  const pdfDocument = document.querySelector(".react-pdf__Document");

  const stopSync = React.useCallback(() => {
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
  }, []);

  const startSync = React.useCallback(() => {
    intervalIDRef.current = setInterval(() => {
      syncDocument(idAudit, idDocument).then((res) => {
        const newSignerStatus = res.document.signers.find(
          (signer) =>
            signer.teamMember?.user.email === user.email ||
            signer.auditorMember?.member.user.email === user.email
        )?.status;

        if (newSignerStatus !== mySignerStatus) {
          setMySignerStatus(newSignerStatus);
        }

        setDocumentData((state) => {
          const isInProgress = res.document.signers.some(
            (signer) => signer.status === "in_progress"
          );
          const areSignersEqual =
            JSON.stringify(res.document.signers.map((i) => i.status)) ===
            JSON.stringify(state.signers.map((i) => i.status));

          if (!areSignersEqual && !isInProgress) {
            stopSync();
            setWindowWidth(windowWidth + 1);
            return {
              ...state,
              signers: res.document.signers,
            };
          }

          if (!areSignersEqual) {
            return {
              ...state,
              signers: res.document.signers,
            };
          }

          return state;
        });
      });
    }, 5000);
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsPdfRendered(false);
      setWindowWidth(window.innerWidth);
    }, 1000);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updateMousePosition = (event) => {
      mousePosition.current = { x: event.clientX, y: event.clientY };
    };

    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  const debounceSetPdfRender = debounce(() => setIsPdfRendered(true), 1000);

  useEffect(() => {
    if (documentData && pdfBlockRef.current) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "childList" &&
            mutation.target.classList.contains("react-pdf__Document")
          ) {
            debounceSetPdfRender();
            observer.disconnect();
          }
        });
      });

      observer.observe(pdfBlockRef.current, { childList: true, subtree: true });

      return () => observer.disconnect();
    }
  }, [debounceSetPdfRender, documentData, pdfBlockRef.current]);

  useEffect(() => {
    if (isPdfRendered && documentData?.signers?.length > 0) {
      setInitialSignatures();
    }
  }, [isPdfRendered, documentData, windowWidth]);

  useEffect(() => {
    return () => clearInterval(intervalIDRef.current);
  }, []);

  useEffect(() => {
    oneDocument(idAudit, idDocument)
      .then((res) => {
        setDocumentData(res.document);
        const signerInProgress = res.document.signers.some(
          (signer) => signer.status === "in_progress"
        );
        if (signerInProgress) {
          startSync();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [idAudit, idDocument, commentAdd]);

  function setInitialSignatures() {
    const signers = documentData.signers.map((signer) => {
      const details = getPdfPagesDetails(pdfDocument);
      const { width, height, top } = details.find((d) => d.page === signer.page) || {};
      const { newX, newY } = recalculateCoordinates(
        signer.percentageX,
        signer.percentageY,
        width,
        height,
        top
      );
      if (newX !== null && newY !== null) {
        return {
          ...signer,
          coordinates: { x: newX, y: newY },
          user: signer.auditorMember?.member?.user || signer.teamMember?.user,
        };
      }
    });
    setNewSignatures(signers.filter((i) => Boolean(i)));
  }

  const sendSignRequest = async (body) => {
    try {
      await signDocument(idAudit, idDocument, body);
      startSync();
    } catch (error) {
      stopSync();
      startSync();
      console.log("error", error);
    }
  };

  const handleOpenSignPopup = () => {
    setIsSignaturePopupOpen(true);
  };

  const handleOpenSharePopup = () => {
    setIsSharePopupOpen(true);
  };

  const handleUpdateName = (name) => {
    setDocumentData((p) => ({ ...p, name }));
  };

  const handleDownload = async () => {
    if (isDownloading) return;

    setIsDownloading(true);
    try {
      const res = await downloadDocument(idAudit, idDocument);
      const result = await downloadFileFromURL(res, documentData.name || documentData.document);

      if (!result) window.open(res, "_blank");
    } finally {
      setIsDownloading(false);
    }
  };

  const closeSignaturePopup = () => {
    setIsSignaturePopupOpen(false);
  };

  const onConfirmSignature = () => {
    closeSignaturePopup();
    setIsSignPopupOpen(true);
  };

  const onDragStart = (start) => {
    const draggedSigner = documentData?.access?.find((s) => s.user.id === +start.draggableId);
    setClonedSigner({ ...draggedSigner, index: start.source.index });
  };

  const handleOnDragEnd = (result) => {
    setClonedSigner(null);
    if (!result.destination || !result.source || !documentData) return;

    if (!pdfDocument) return;

    const pdfPagesDetails = getPdfPagesDetails(pdfDocument);
    const dropDetails = getDropPositionDetails(mousePosition.current, pdfDocument, pdfPagesDetails);

    if (!dropDetails) return;

    const { x, y, page, pageDetails } = dropDetails;

    const signerData = documentData.access.find((signer) => signer.user.id === +result.draggableId);
    if (!signerData) return;

    const currentDragOffsetValues = dragOffsetPercentage.current;

    const width = pageDetails.width * 0.33; // 33% is the default width of signature badge
    const height = width / 2.5;
    const absoluteOffsetX = width * currentDragOffsetValues.offsetXPercentage;
    const absoluteOffsetY = height * currentDragOffsetValues.offsetYPercentage;

    const newX = x - absoluteOffsetX;
    const newY = y - absoluteOffsetY;
    const signer = {
      ...signerData,
      id: `temporary-${new Date().getTime()}`,
      coordinates: { x: newX, y: newY },
      positionY: newY - pageDetails.top,
      positionX: newX,
      page,
      pageDetails,
    };

    setNewSignatures((prev) => {
      const isExisting = prev.some((s) => s.userId === signer.user.id);
      if (isExisting) return prev;

      return [...prev, signer];
    });
  };

  function getPdfPagesDetails(pdfDocument) {
    const { top: pdfTop, left: pdfLeft } = pdfDocument.getBoundingClientRect();

    return Array.from(pdfDocument.children)
      .filter((el) => el.tagName === "DIV")
      .map((el) => {
        const { width, height, top, left } = el.getBoundingClientRect();
        const pageElement = el.querySelector(".react-pdf__Page");
        const page = pageElement ? Number(pageElement.getAttribute("data-page-number")) : 0;

        return {
          width,
          height,
          page,
          top: top - pdfTop,
          left: left - pdfLeft,
        };
      });
  }

  const getDropPositionDetails = (mousePosition, pdfDocument, pdfPagesDetails) => {
    const { x: mouseX, y: mouseY } = mousePosition;
    const { left: pdfLeft, top: pdfTop } = pdfDocument.getBoundingClientRect();

    const dropEl = document.elementsFromPoint(mouseX, mouseY);
    const dropElPage =
      dropEl.find((el) => el.hasAttribute("data-page-number")) ||
      document.getElementsByClassName("react-pdf__Page")[0];
    const page = dropElPage ? Number(dropElPage.getAttribute("data-page-number")) : null;

    if (!page) return null;

    const pageDetails = pdfPagesDetails.find((i) => i.page === page);
    if (!pageDetails) return null;

    const x = mouseX < pdfLeft ? 0 : mouseX - pdfLeft;
    const y = mouseY < pdfTop ? 0 : mouseY - pdfTop;

    return { x, y, page, pageDetails };
  };

  const updateSignerPosition = (index, coordinates) => {
    if (!showSignatures) {
      setShowSignatures(true);
    }
    const pdfPagesDetails = getPdfPagesDetails(pdfDocument);
    const dropDetails = getDropPositionDetails(mousePosition.current, pdfDocument, pdfPagesDetails);
    setNewSignatures((prev) => {
      if (!prev.length) return [];
      const newSigners = [...prev];
      newSigners[index].pageDetails = dropDetails.pageDetails;
      newSigners[index].coordinates = coordinates;
      newSigners[index].page = dropDetails.page;
      newSigners[index].positionY = coordinates.y;
      newSigners[index].positionX = coordinates.x;
      return newSigners;
    });
  };

  const handleRemoveSignerById = (id) => {
    if (!`${id}`.includes("temporary")) {
      setDeletedSignatures((prev) => [...prev, id]);
    }
    if (!showSignatures) {
      setShowSignatures(true);
    }
    setNewSignatures((prev) => prev.filter((signer) => signer.id !== id));
  };

  const handleCancelUpdateSignatures = () => {
    setShowSignatures(false);
    setInitialSignatures();
  };

  const handleUpdateDocumentSignatures = async () => {
    const signers = newSignatures
      .filter((i) => i.pageDetails)
      .map((signer) => {
        const signElementSize = document.getElementById(signer.id)?.getBoundingClientRect();

        return {
          userId: signer.user.id,
          page: signer.pageDetails.page,
          percentageX: signer.positionX / signer.pageDetails.width,
          percentageY: (signer.positionY - signer.pageDetails.top) / signer.pageDetails.height,
          signatureWidth: signElementSize?.width / signer.pageDetails.width,
          signatureHeight: signElementSize?.height / signer.pageDetails.height,
        };
      });
    try {
      const res = await updateDocumentSignatures({
        auditId: idAudit,
        documentId: idDocument,
        data: {
          signers,
          deletedSigners: deletedSignatures,
        },
      });
      setDocumentData((prev) => ({
        ...prev,
        signers: res.signers,
      }));
      setShowSignatures(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignatureClick = (event) => {
    const element = event.currentTarget;
    const rect = element.getBoundingClientRect();
    dragOffsetPercentage.current = {
      offsetXPercentage: (event.clientX - rect.left) / rect.width,
      offsetYPercentage: (event.clientY - rect.top) / rect.height,
    };
    console.log("Captured onMouseDown:", dragOffsetPercentage.current);
  };

  return (
    documentData && (
      <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId='signersList'>
          {(provided) => (
            <div className={styles.wrapper}>
              <SignaturePreviewPopup
                open={isSignaturePopupOpen}
                onClose={closeSignaturePopup}
                onConfirm={onConfirmSignature}
              />
              <div className={styles.header}>
                <Space size={24}>
                  <Button
                    className={styles.buttonBack}
                    icon={<ArrowLeftIcon width={24} height={24} />}
                    onClick={showSignatures ? handleCancelUpdateSignatures : history.goBack}
                  />
                  <h4 className={styles.title}>
                    {t(showSignatures ? "signatures" : "document_preview")}
                  </h4>
                </Space>
                <Space size={10}>
                  {hasInProgressSigners && (
                    <div className='being-signed'>
                      <Trans
                        t={t}
                        i18nKey='currently_signed'
                        values={{
                          name: toFullName(signerUser),
                        }}
                      />
                    </div>
                  )}
                  <Button small primary color='lightBlue' onClick={handleOpenSharePopup}>
                    <UserAddIcon />
                    {tGlobal("share")}
                  </Button>
                  {showSignatures ? (
                    <>
                      <Button primary color='lightRed' onClick={handleCancelUpdateSignatures}>
                        {tGlobal("cancel")}
                      </Button>
                      <Button primary color='green' onClick={handleUpdateDocumentSignatures}>
                        {tGlobal("send")}
                      </Button>
                    </>
                  ) : (
                    <Button
                      primary
                      color='green'
                      disabled={!isUnsigned || hasInProgressSigners || !mySigner}
                      onClick={handleOpenSignPopup}
                    >
                      {t("sign")}
                    </Button>
                  )}
                </Space>
              </div>
              <div className='content-block'>
                <StartSigningPopup
                  mySignerStatus={mySignerStatus}
                  sendSignRequest={sendSignRequest}
                  ids={{ idAudit, idDocument }}
                  isOpen={isSignPopupOpen}
                  close={() => setIsSignPopupOpen(false)}
                />
                <div
                  style={{
                    minHeight: documentData.status ? "" : "0px",
                    height: documentData.status ? "" : "max-content",
                  }}
                  id='pdfSidebar'
                  className='info-block'
                >
                  <div className='info-block-row'>
                    <div className={styles.fileNameWrapper}>
                      <FileExtension document={documentData.document} />
                      <EditableDocumentName
                        id={documentData.id}
                        className={styles.fileName}
                        name={documentData.name}
                        onSave={handleUpdateName}
                      />
                    </div>
                    <Button primary small color='lightBlue' onClick={handleDownload}>
                      <DownloadRoundedSmallIcon fill='currentColor' />
                      <span>{t("download_file")}</span>
                    </Button>
                  </div>

                  <DocumentViewSignatures
                    clonedSigner={clonedSigner}
                    document={documentData}
                    showSignatures={showSignatures}
                    signers={newSignatures}
                    onSetShowSignatures={setShowSignatures}
                    onRemoveSigner={handleRemoveSignerById}
                    onSignatureClick={handleSignatureClick}
                  />

                  <div className='info-block-row pd-by'>
                    <span className='title-table'>{t("uploaded_by")}</span>
                    <div className='block-by block-bordered'>
                      <div className='block-by-content'>
                        <CustomAvatar user={documentData.addedBy} width={24} height={24} />
                        <span style={{ marginTop: 0 }} className='content-table'>
                          {toFullName(documentData.addedBy)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='info-block-row'>
                    <span className='title-table'>{t("uploaded_on")}</span>
                    <span className='content-table'>
                      <CalendarIcon />
                      {dayjs(documentData.addedAt).format("D MMM YYYY, HH.mm")}
                    </span>
                  </div>
                  {documentData.uploaded === true && (
                    <div className='button-arrows_uploadPage'>
                      <div
                        className='button-arrows'
                        onClick={() => {
                          if (selector?.requestsAccess) {
                            history.push(
                              `/dashboard/${idAudit}/requests/${documentData.request.id}`
                            );
                          }
                        }}
                      >
                        <img src={arrows} alt='Download' />
                        <span className='button-arrows_inner'>{documentData.request.name}</span>
                      </div>
                    </div>
                  )}
                  <DocumentViewComments
                    data={documentData.comments}
                    onAfterSubmit={() => setCommentAdd(!commentAdd)}
                  />
                </div>

                <div {...provided.droppableProps} ref={provided.innerRef} className='pdf-block'>
                  <div className='pdf-view' ref={pdfBlockRef}>
                    <FilePreview key={windowWidth} url={documentData.document} />
                  </div>
                  <div style={{ background: "red" }}>{provided.placeholder}</div>
                </div>
              </div>
              {isPdfRendered &&
                newSignatures
                  ?.filter((i) => i.status !== DocumentSignaturesStatus.signed)
                  .map((signer, index) =>
                    createPortal(
                      <Signer
                        key={signer.id}
                        signer={signer}
                        index={index}
                        isEditable={showSignatures}
                        updateSignerPosition={updateSignerPosition}
                        onClose={() => handleRemoveSignerById(signer.id)}
                      />,
                      pdfDocument
                    )
                  )}
              <DocumentSharePopup
                document={documentData}
                setDocument={setDocumentData}
                open={isSharePopupOpen}
                onCancel={() => setIsSharePopupOpen(false)}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  );
};

export default DocumentView;
