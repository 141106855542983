import { Tag } from "antd";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Line } from "rc-progress";
import moment from "moment";

import toFullName from "../../../../../consts/toFullName";
import {
  FinishedStatusArrow,
  InProgressStatusArrow,
  NotStartedStatusArrow,
} from "../../../../../assets/icons";
import { isTaskDone } from "../../../../../helpers/isTaskDone";
import { StatusStep } from "../../../../../types/status";
import styles from "./StatusSection.module.scss";

interface StatusGroup {
  id: string;
  order: number;
  name: string;
  steps: StatusStep[];
}

interface Auditor {
  firstName: string;
  lastName: string;
}

interface Firm {
  name: string;
}

interface Audit {
  name: string;
  reportDate?: string;
  statusGroups: StatusGroup[];
}

interface StatusSectionProps {
  audit?: Audit;
  selectedStatusGroup: string;
  statusGroups: StatusGroup[];
  auditor?: Auditor;
  auditorFirm?: Firm;
}

const StatusSection: React.FC<StatusSectionProps> = ({
  audit,
  selectedStatusGroup,
  statusGroups,
  auditor,
  auditorFirm,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "global" });

  const [selectedGroupNumber, selectedGroupData] = useMemo(() => {
    if (!audit) return [null, null];
    const sortedGroups = audit.statusGroups.sort((a, b) => a.order - b.order);
    const idx = sortedGroups.findIndex((group) => group.id === selectedStatusGroup);
    return [idx + 1, sortedGroups[idx]];
  }, [audit, selectedStatusGroup]);

  const allSteps = useMemo(() => statusGroups.flatMap((group) => group.steps), [statusGroups]);
  const allStepAmount = allSteps.length;
  const doneStepAmount = allSteps.filter(isTaskDone).length;
  const progress = allStepAmount ? (doneStepAmount / allStepAmount) * 100 : 0;

  const progressGridStyles = useMemo(() => {
    const finishedStepCount = selectedGroupNumber ? selectedGroupNumber - 1 : 0;
    return {
      gridTemplateColumns: `${
        finishedStepCount ? `repeat(${finishedStepCount}, minmax(100px, 10%))` : ""
      } repeat(${statusGroups.length - finishedStepCount}, minmax(100px, 1fr))`,
    };
  }, [selectedGroupNumber, statusGroups.length]);

  if (!audit || !selectedGroupData) return null;

  return (
    <div className={styles.body}>
      <div className={styles.bodyInner}>
        <div>
          <span className={styles.title}>{audit.name}</span>
          <div className={styles.descriptionWrapper}>
            {auditorFirm && (
              <p className={styles.description}>
                {t("audit_company")}: {auditorFirm.name}
              </p>
            )}
            {auditor && (
              <p className={styles.description}>
                {t("role_lead_auditor")}: {toFullName(auditor)}
              </p>
            )}
          </div>
        </div>
        <div className='progress_line'>
          <span className={styles.title}>{t("progress")}</span>
          <p className='percent'>{progress.toFixed(0)}%</p>
          <Line
            percent={progress}
            className={styles.progress}
            strokeWidth={4}
            trailColor='rgba(0, 62, 71, 0.1)'
            trailWidth={4}
            strokeColor='#6DB333'
          />
        </div>
        <div className='time'>
          <span className={styles.title}>{t("audit_report_date")}</span>
          {audit.reportDate && (
            <div className={clsx(styles.descriptionWrapper, styles.alignRight)}>
              <p className={styles.description}>{moment(audit.reportDate).format("DD.MM.YYYY")}</p>
              <p className={styles.description}>{moment(audit.reportDate).fromNow()}</p>
            </div>
          )}
        </div>
      </div>

      <div className='progress_bar'>
        <div className='progress_line' style={progressGridStyles}>
          {statusGroups
            .sort((a, b) => a.order - b.order)
            .map((group, idx) => {
              const isDone = group.steps.every(isTaskDone);
              const isInProgress = group.steps.some((step) => step.status === "in_progress");
              const zIdx = statusGroups.length - idx;

              const IconComponent = isDone
                ? FinishedStatusArrow
                : isInProgress
                ? InProgressStatusArrow
                : NotStartedStatusArrow;

              return (
                <div key={group.id} style={{ zIndex: zIdx }} className='status-progress-arrow'>
                  <IconComponent />
                  <div className={styles.statusLabelWrapper}>
                    <Tag className={styles.statusLabel} title={`${idx + 1}. ${group.name}`}>
                      {idx + 1}. {group.name}
                    </Tag>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default StatusSection;
