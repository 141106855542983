import { Input } from "antd";
import clsx from "clsx";
import React, { ChangeEvent, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as IdentityIcon } from "../../../../../assets/icons/identity-icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search.svg";

import CustomAvatar from "../../../../../components/CustomAvatar";
import { Button, Label } from "../../../../../components/ui-components";

import toFullName from "../../../../../consts/toFullName";
import { DocumentSignaturesStatus } from "../../../../../enums";
import { RootState } from "../../../../../store";
import { Document, DocumentAccess, DocumentSigner } from "../../../../../types/document";
import SignerInfo from "../SignerInfo";

import styles from "./DocumentViewSignatures.module.scss";

type Props = {
  clonedSigner?: any;
  document: Document | null;
  showSignatures: boolean;
  signers: Array<DocumentSigner & { user: DocumentAccess["user"] }>;

  onSetShowSignatures: (v: boolean) => void;
  onRemoveSigner: (id: number) => void;
  onSignatureClick: (e: React.MouseEvent) => void;
};

export default function DocumentViewSignatures({
  clonedSigner,
  document,
  showSignatures,
  signers,

  onRemoveSigner,
  onSetShowSignatures,
  onSignatureClick,
}: Props) {
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const user = useSelector((state: RootState) => state.user.user_data);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredSigners = document?.access?.filter((signer) => {
    const fullName = signer.user.firstName
      ? `${signer.user.firstName} ${signer.user.lastName}`
      : signer.user.email;
    return fullName.toLowerCase().includes(searchQuery);
  });

  return (
    <>
      {showSignatures && (
        <div className={styles.newSignersWrapper}>
          <div className={styles.newSignersHero}>
            <Input prefix={<SearchIcon />} onChange={handleSearch} />
            <div className={styles.description}>
              <Trans t={t} i18nKey='signers_list_description' components={{ b: <b /> }} />
            </div>
          </div>
          <div className={styles.newSignersBody}>
            {filteredSigners?.map((signer, index) => {
              const selected = signers.find((s) => s.user.id === signer.userId);
              const signatures = signers.filter((s) => s.user.id === signer.userId)?.length;

              return (
                <Draggable
                  isDragDisabled={!!selected}
                  key={signer.user.id}
                  draggableId={signer.user.id + ""}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <SignerInfo
                      onMouseDown={onSignatureClick}
                      signer={signer}
                      isSelected={!!selected}
                      footnoteText={
                        signatures > 0
                          ? `${signatures} signature${signatures > 1 ? "s" : ""} required`
                          : ""
                      }
                      {...(selected &&
                        selected.status !== DocumentSignaturesStatus.signed && {
                          onClose: () => onRemoveSigner(selected.id),
                        })}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        ...(index > clonedSigner?.index && { order: 3 }),
                        ...(snapshot.isDropAnimating && {
                          opacity: 0,
                          transitionDuration: "2ms",
                        }),
                      }}
                    />
                  )}
                </Draggable>
              );
            })}
            {clonedSigner && (
              <SignerInfo
                signer={clonedSigner}
                isSelected={false}
                style={{ order: 2, opacity: 0.5 }}
              />
            )}
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Label>{t("table_header_signatures")}</Label>
          <div>
            {document?.signers?.length ? (
              <Button primary color='lightBlue' onClick={() => onSetShowSignatures(true)}>
                Edit
              </Button>
            ) : (
              <Button
                color='lightBlue'
                icon={<PlusIcon />}
                onClick={() => onSetShowSignatures(true)}
              />
            )}
          </div>
        </div>
        {document?.signers?.length ? (
          <div className={styles.list}>
            {document.signers.map((signer) => {
              const currentUser =
                signer.teamMember?.user || signer.auditorMember?.member.user || {};
              const isMe = currentUser.email === user.email;

              return (
                <div key={signer.id} className={styles.signer}>
                  <div className={styles.userInfo}>
                    <CustomAvatar user={currentUser} width={24} height={24} />
                    <div className={styles.userName}>
                      {toFullName(currentUser)} {isMe && "(You)"}
                    </div>
                  </div>
                  <span className={clsx(styles.status, styles[signer.status])}>
                    {signer.status.split("_").join(" ")}
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.empty}>
            <IdentityIcon />
            <div className={styles.emptyText}>{t("no_signatures_requested")}</div>
          </div>
        )}
      </div>
    </>
  );
}
