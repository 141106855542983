import React, { useEffect, useState } from "react";
import file from "../assets/admin/file.svg";
import "./styles/newDocument.css";
import CheckData from "./CheckData";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDocuments } from "../hooks/services/useDocuments";
import FormPicker from "../Admin/pages/components/FormPicker/FormPicker";
import { categoryOptions } from "../pages/dashboard/Documents/Documents.constant";

const EditDocument = ({ docName, docFile, docType, closeDrop, id, requiresSignature }) => {
  const [activeData, setActiveData] = useState(false);
  const { id: idAudit } = useParams();
  const [data, setData] = useState({
    name: docName,
    file: docFile,
    requiresSignature: requiresSignature,
    type: docType,
  });
  const { updateDocument } = useDocuments();

  useEffect(() => {
    setActiveData((data.name !== docName && data.name.length > 0) || data.type !== docType);
  }, [data, docName, docType]);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (activeData) {
      closeDrop(null);
      updateDocument({
        body: {
          name: data.name,
          requiresSignature,
          type: data.type,
        },
        documentId: id,
        auditId: idAudit,
      });
    }
  };

  const editData = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  return (
    <div className='sub_menu_document'>
      <div className='settings_right-audit'>
        <span className='popup-title'>{t("edit_document")}</span>
        <hr className='documents_line-create' />
        <div className='block-input_edit'>
          <div className='block-row_edit'>
            <span className='title-input'>{t("upload_file")}</span>
            <div className='file-down-block'>
              <div className='file-down-row-block'>
                <div className='file-down-name-blocks'>
                  <img className='file-down-name-blockFile' src={file} alt='File' />
                  <span className='title-file-name'> {docFile}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='block-row_edit'>
            <span className='title-input'>{t("filename")}</span>
            <input
              className={"inputNewDocuments"}
              placeholder='e.g. Angebot'
              value={data.name}
              onChange={({ target }) => {
                editData("name", target.value);
              }}
            />
          </div>

          <div className='block-row_edit'>
            <span className='title-input'>{t("category")}</span>
            <FormPicker
              options={categoryOptions.map((i) => ({ name: t(i), value: i }))}
              selected={data.type}
              name='type'
              onSelect={editData}
            />
          </div>
        </div>
        <hr className='documents_line-create' />
        <div className='addAuditBtn'>
          <CheckData
            discard={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              closeDrop(null);
            }}
            onClick={onSubmit}
            text={tGlobal("save")}
            activeBtn={activeData}
          />
        </div>
      </div>
    </div>
  );
};

export default EditDocument;
